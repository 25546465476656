import { FormikValues, getIn } from 'formik';

export const getShouldMarkFieldAsUpdated = (
  form: FormikValues,
  fieldName: string,
  field: {
    value: any;
  },
  isFieldUpdate: boolean,
): boolean => {
  return getIn(form.initialValues, fieldName) !== field.value && isFieldUpdate;
};
export type FormElementProps = {
  label?: string | JSX.Element;
  fieldName: string;
  disabled: boolean;
  onChange?: (e: any) => void;
  isFieldUpdate: boolean;
};
