import { Grid, Typography } from '@mui/material';

import React from 'react';
import moment from 'moment';
import { AccountData, Currency } from '../api';

type DeepOmitNull<T> = {
  [K in keyof T]: T[K] extends object ? DeepOmitNull<T[K]> : T[K];
};

export function priceFormatterFromCurrency(price: number, currency: Currency, taxRate?: number, withTax = false) {
  const locale = currency.toLowerCase() === 'ils' ? 'il-IL' : 'en-US';
  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency,
    maximumFractionDigits: 0,
  });
  const formattedPrice = formatter.format(price);
  const shouldRenderTax = taxRate && withTax;
  return shouldRenderTax ? (
    <Grid container gap={1} alignItems="center">
      <Typography>{formattedPrice}</Typography>
      <Typography fontSize={'12px'} fontWeight={300}>{` + ${taxRate}% sales tax`}</Typography>
    </Grid>
  ) : (
    formattedPrice
  );
}

export function deepOmitNull<T>(obj: T): DeepOmitNull<T> {
  if (!obj) {
    return obj;
  }
  return Object.fromEntries(
    Object.entries(obj)
      .filter(([_, value]) => value !== null)
      .map(([key, value]) => [key, value !== null && typeof value === 'object' ? deepOmitNull(value) : value]),
  ) as DeepOmitNull<T>;
}

export function priceFormatter(price: number, account: AccountData, withTax = false) {
  return priceFormatterFromCurrency(price, account.currency, account.stripe_connect?.stripe_tax_rate, withTax);
}

export function getCurrencySymbol(account: AccountData) {
  const locale = account.currency.toLowerCase() === 'ils' ? 'il-IL' : 'en-US';

  const formatter = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: account.currency,
    maximumFractionDigits: 0,
  });

  return formatter.format(0).replace(/[0\s,]/g, '');
}

export function dateFormatter(date: moment.Moment, account: AccountData) {
  return date.format(account.currency.toLowerCase() === 'ils' ? 'DD/MM/YYYY' : 'MM/DD/YYYY');
}
