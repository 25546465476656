import React, { useEffect, useState } from 'react';
import {
  Alert,
  Grid,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import { Delete, HelpOutline, Link, Loyalty, Percent } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import {
  AccountData,
  DiscountOut,
  DiscountService,
  ExtraPaymentLineData,
  OrderData,
  OrderService,
  PaymentLinkOut,
  PaymentLinkPaymentStatus,
  PaymentLinkService,
  PaymentLinkType,
} from '../api';
import RotatingLoader from './ui/RotatingLoader';
import { useAuth } from '../session/InternalAuthProvider';
import TextWithTooltip from './ui/TextWithTooltip';
import { getLinkToPaymentLink, getPaymentLinkStatus } from '../calendar/dialogs/EditOrderDialog/OrderDetails';
import { DATE_FORMAT } from '../calendar/CalendarWrapper';
import { usePriceFormatter } from './usePriceFormatter';

const calculateTotalPrice = (
  extraPaymentsLines: ExtraPaymentLineData[],
  paymentLinks: PaymentLinkOut[],
  initialPrice: number,
  workshopPaidWithPaymentLink: boolean,
  discount?: DiscountOut,
  originalOrderForReturningCustomer?: OrderData,
  returningCustomerSettingsValue?: number,
) => {
  const extraPaymentLinesPrice = extraPaymentsLines.reduce(
    (total: number, obj: ExtraPaymentLineData) => total + obj.price,
    0,
  );
  const paymentLinksPrice = paymentLinks.reduce((total: number, obj: PaymentLinkOut) => total + obj.price, 0);
  const total = extraPaymentLinesPrice + paymentLinksPrice;

  const discountedPrice = discount
    ? ((100 - (discount?.value || 0)) * initialPrice) / 100
    : originalOrderForReturningCustomer
    ? ((100 - (returningCustomerSettingsValue || 0)) * initialPrice) / 100
    : initialPrice;
  return workshopPaidWithPaymentLink ? total : total + discountedPrice;
};
type UsePriceRendererProps = {
  orderId: string;
  discountId?: string;
  initialPrice: number;
  returningCustomerFromOrderId?: string;
  extraPaymentLines: ExtraPaymentLineData[];
  isVoucher?: boolean;
};
type UsePriceRendererResult = {
  SlimPrice: () => JSX.Element;
  FullPriceBreakdown: ({ isCustomisingPrice }: { isCustomisingPrice: boolean }) => JSX.Element;
  setPaymentLinks: (paymentLinks: PaymentLinkOut[]) => void;
};
export const usePriceRenderer = ({
  orderId,
  discountId,
  initialPrice,
  returningCustomerFromOrderId,
  extraPaymentLines,
  isVoucher,
}: UsePriceRendererProps): UsePriceRendererResult => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const [isLoadingDiscount, setIsLoadingDiscount] = useState(false);
  const [isLoadingPaymentLinks, setIsLoadingPaymentLinks] = useState(false);
  const [isLoadingOriginalOrder, setIsLoadingIOriginalOrder] = useState(false);
  const [discount, setDiscount] = useState<DiscountOut | undefined>(undefined);
  const [originalOrderForReturningCustomer, setOriginalOrderForReturningCustomer] = useState<OrderData | undefined>(
    undefined,
  );
  const [paymentLinks, setPaymentLinks] = useState<PaymentLinkOut[]>([]);
  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);
  const priceFormatter = usePriceFormatter();
  const workshopPaidWithPaymentLink =
    paymentLinks.find((x) => x.payment_link_type === PaymentLinkType.MAIN_PAYMENT && x.price === initialPrice) !==
    undefined;
  const isLoadingResources = isLoadingDiscount || isLoadingPaymentLinks || isLoadingOriginalOrder;
  useEffect(() => {
    const getDiscount = async () => {
      if (discountId) {
        setIsLoadingDiscount(true);
        // currently supported just percentage
        const discountOut = await DiscountService.getDiscountById(discountId);
        setDiscount(discountOut);
        setIsLoadingDiscount(false);
      }
    };

    void getDiscount();
  }, [discountId]);

  useEffect(() => {
    const getOriginalOrderForReturningCustomer = async () => {
      if (returningCustomerFromOrderId) {
        setIsLoadingIOriginalOrder(true);
        // currently supported just percentage

        const originalOrder = await OrderService.getOrder(returningCustomerFromOrderId);
        setOriginalOrderForReturningCustomer(originalOrder);
        setIsLoadingIOriginalOrder(false);
      }
    };
    void getOriginalOrderForReturningCustomer();
  }, [returningCustomerFromOrderId]);
  const getPaymentLinks = async () => {
    setIsLoadingPaymentLinks(true);
    const paymentLinks = await PaymentLinkService.getPaymentLinkByOrderId(orderId);
    setPaymentLinks(paymentLinks.filter((x) => !x.archived));

    setIsLoadingPaymentLinks(false);
  };
  useEffect(() => {
    if (orderId && !isVoucher) {
      void getPaymentLinks();
    }
  }, [orderId]);

  const totalPrice = calculateTotalPrice(
    extraPaymentLines,
    paymentLinks,
    initialPrice,
    workshopPaidWithPaymentLink,
    discount,
    originalOrderForReturningCustomer,
    account.returning_customer_settings?.value,
  );
  const SlimPrice = () => {
    if (isLoadingResources) {
      return <RotatingLoader width={'24px'} />;
    }

    if (!discount && !originalOrderForReturningCustomer) {
      return <Grid>{priceFormatter(totalPrice)}</Grid>;
    }
    if (discount) {
      return (
        <Grid container alignItems="center" gap={1}>
          <Tooltip
            title={
              <Grid container flexDirection="column" gap={-0.5}>
                <Grid item container>
                  <Typography>{`${t('calendar.order_popper.discountCode')}`}</Typography>
                </Grid>
              </Grid>
            }
            arrow>
            <Percent style={{ color: discount.value === 0 ? '#49B909' : '#bababa' }} />
          </Tooltip>
          {priceFormatter(totalPrice)}
        </Grid>
      );
    }
    if (originalOrderForReturningCustomer) {
      return (
        <Grid container alignItems="center" gap={1}>
          <Tooltip
            title={
              <Grid container flexDirection="column" gap={-0.5}>
                <Grid item container gap={1}>
                  <Typography>{`${t('calendar.order_popper.returningCustomerDiscount')}`}</Typography>
                </Grid>
              </Grid>
            }
            arrow>
            <Loyalty style={{ color: '#bababa' }} />
          </Tooltip>
          {`${priceFormatter(totalPrice)}`}
        </Grid>
      );
    }
    return <Grid container />;
  };
  const FullPriceBreakdown = ({ isCustomisingPrice }: { isCustomisingPrice: boolean }) => {
    if (isLoadingResources) {
      return <RotatingLoader width={'24px'} />;
    }
    return (
      <Grid>
        <Table size="small" stickyHeader>
          <TableBody>
            {!isCustomisingPrice && (
              <TableRow>
                <TableCell>
                  {isSmallScreen ? (
                    <TextWithTooltip
                      text={t('calendar.edit_order.order_details.price').slice(0, 20)}
                      tooltip={t('calendar.edit_order.order_details.price')}
                      width={'120'}
                      variant={'body1'}
                    />
                  ) : (
                    <Typography>{t('calendar.edit_order.order_details.price')}</Typography>
                  )}
                </TableCell>
                <TableCell style={{ minWidth: '70px' }}>
                  <Typography>{priceFormatter(initialPrice)}</Typography>
                </TableCell>
                <TableCell>
                  {workshopPaidWithPaymentLink ? (
                    <Grid
                      onClick={() => {
                        void navigator.clipboard.writeText(getLinkToPaymentLink(paymentLinks[0].id));
                        setCopySnackbarOpen(true);
                      }}>
                      <Tooltip title={t('calendar.edit_order.order_details.paymentLink')}>
                        <Link style={{ color: '#bababa' }} />
                      </Tooltip>
                      {getPaymentLinkStatus(paymentLinks[0].payment_status, t)}
                    </Grid>
                  ) : null}
                </TableCell>
              </TableRow>
            )}
            {discount && (
              <TableRow>
                <TableCell>
                  <Typography>{t('calendar.order_popper.discountCode')}</Typography>
                </TableCell>
                <TableCell style={{ minWidth: '70px' }}>
                  <Typography>{priceFormatter(-((discount?.value || 0) * initialPrice) / 100)}</Typography>
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      <Grid container flexDirection="column" gap={-0.5}>
                        <Grid item container gap={1}>
                          <Typography>{`${t('calendar.order_popper.discountCode')}:`}</Typography>
                          <Typography>{discount.code}</Typography>
                        </Grid>
                        <Grid item container gap={1}>
                          <Typography>{`${t('calendar.order_popper.value')}:`}</Typography>
                          <Typography>{`${discount.value}%`}</Typography>
                        </Grid>
                      </Grid>
                    }
                    arrow>
                    <HelpOutline style={{ color: '#bababa' }} />
                  </Tooltip>
                </TableCell>
              </TableRow>
            )}
            {returningCustomerFromOrderId && (
              <TableRow>
                <TableCell>
                  <Typography>{t('calendar.order_popper.returningCsutomerDiscount')}</Typography>
                </TableCell>
                <TableCell style={{ minWidth: '70px' }}>
                  <Typography>
                    {priceFormatter(-((account.returning_customer_settings?.value || 0) * initialPrice) / 100)}
                  </Typography>
                </TableCell>
                <TableCell>
                  <Tooltip
                    title={
                      <Grid container flexDirection="column" gap={-0.5}>
                        <Grid item container gap={1}>
                          <Typography>{`${t('calendar.order_popper.value')}:`}</Typography>
                          <Typography>{`${account.returning_customer_settings?.value}%`}</Typography>
                        </Grid>
                        <Grid item container gap={1}>
                          <Typography>{`${t('calendar.order_popper.originalOrder')}:`}</Typography>
                          <Typography>{`#${originalOrderForReturningCustomer?.order_number}`}</Typography>
                        </Grid>
                        <Grid item container gap={1}>
                          <Typography>{`${t('calendar.order_popper.name')}:`}</Typography>
                          <Typography>{`${originalOrderForReturningCustomer?.firstname} ${originalOrderForReturningCustomer?.lastname}`}</Typography>
                        </Grid>
                        <Grid item container gap={1}>
                          <Typography>{`${t('calendar.order_popper.date')}:`}</Typography>
                          <Typography>{moment(originalOrderForReturningCustomer?.date).format(DATE_FORMAT)}</Typography>
                        </Grid>
                      </Grid>
                    }
                    arrow>
                    <HelpOutline style={{ color: '#bababa' }} />
                  </Tooltip>
                </TableCell>
              </TableRow>
            )}
            {(extraPaymentLines?.length > 0 || paymentLinks?.length > 0) && (
              <TableRow>
                <Grid mt={1}>
                  <Typography fontWeight="700">{t('calendar.edit_order.order_details.otherItems')}</Typography>
                </Grid>
              </TableRow>
            )}
            {extraPaymentLines?.map((extraPaymentsLine: ExtraPaymentLineData) => {
              return (
                <TableRow>
                  <TableCell>
                    {isSmallScreen ? (
                      <TextWithTooltip
                        text={extraPaymentsLine.title?.slice(0, 15) || ''}
                        tooltip={extraPaymentsLine.title || ''}
                        width={'120'}
                        variant={'body1'}
                      />
                    ) : (
                      <Typography>{extraPaymentsLine.title}</Typography>
                    )}
                  </TableCell>
                  <TableCell style={{ minWidth: '70px' }}>
                    <Typography>{priceFormatter(extraPaymentsLine.price)}</Typography>
                  </TableCell>
                  <TableCell />
                </TableRow>
              );
            })}

            {paymentLinks.map((paymentLink: PaymentLinkOut) => {
              if (
                workshopPaidWithPaymentLink &&
                paymentLink.payment_link_type === PaymentLinkType.MAIN_PAYMENT &&
                paymentLink.price === initialPrice
              ) {
                return null;
              }
              const disableDelete = paymentLink.payment_status === PaymentLinkPaymentStatus.PAID;
              return (
                <TableRow>
                  <TableCell>
                    <Grid container gap={0.5}>
                      {isSmallScreen ? (
                        <TextWithTooltip
                          text={paymentLink.title?.slice(0, 15) || ''}
                          tooltip={paymentLink.title || ''}
                          width={'120'}
                          variant={'body1'}
                        />
                      ) : (
                        <Typography>{paymentLink.title}</Typography>
                      )}
                    </Grid>
                  </TableCell>
                  <TableCell style={{ minWidth: '70px' }}>
                    <Typography>{priceFormatter(paymentLink.price)}</Typography>
                  </TableCell>
                  <TableCell>
                    <Grid container alignItems="center">
                      <Grid
                        onClick={() => {
                          void navigator.clipboard.writeText(getLinkToPaymentLink(paymentLink.id));
                          setCopySnackbarOpen(true);
                        }}>
                        <Tooltip title={t('calendar.edit_order.order_details.paymentLink')}>
                          <Link style={{ color: '#bababa' }} />
                        </Tooltip>
                        {getPaymentLinkStatus(paymentLink.payment_status, t)}
                      </Grid>
                      <Grid
                        style={{ cursor: 'pointer' }}
                        onClick={async () => {
                          if (!disableDelete) {
                            await PaymentLinkService.archivePaymentLink({ payment_link_id: paymentLink.id });
                            void getPaymentLinks();
                          }
                        }}>
                        <Tooltip
                          title={
                            disableDelete
                              ? t('calendar.edit_order.order_details.deleteDisabled')
                              : t('calendar.edit_order.order_details.delete')
                          }>
                          <Delete style={{ color: '#bababa' }} />
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              );
            })}
            <TableRow>
              <TableCell>
                <Typography fontWeight="700">{t('calendar.edit_order.order_details.totalPayments')}</Typography>
              </TableCell>

              <TableCell style={{ minWidth: '70px' }}>
                <Typography fontWeight="700">{priceFormatter(totalPrice)}</Typography>
              </TableCell>
              <TableCell />
            </TableRow>
          </TableBody>
        </Table>
        <Snackbar
          open={copySnackbarOpen}
          onClose={() => setCopySnackbarOpen(false)}
          autoHideDuration={2000}
          anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
          <Alert onClose={() => setCopySnackbarOpen(false)} severity="success">
            {t('copiedToClipboard')}
          </Alert>
        </Snackbar>
      </Grid>
    );
  };

  return { SlimPrice, FullPriceBreakdown, setPaymentLinks };
};
