import Grid from '@mui/material/Grid';
import { Form, Formik } from 'formik';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as Yup from 'yup';
import { OrderPageButton } from '../order_page/themedComponents/OrderPageButton';
import { OrderPageTypography } from '../order_page/themedComponents/OrderPageTypography';
import { OrderPageTextField } from '../order_page/themedComponents/OrderPageTextField';
import { AccountData, OrderData, OrderService } from '../api';

export const CollectOrderDetailsStep = ({
  setOrder,
  setNotFound,
}: {
  setNotFound: () => void;
  setOrder: (order: OrderData, account: AccountData) => void;
}) => {
  const { t } = useTranslation();
  const [loadingOrder, setLoadingOrder] = useState(false);

  const handleSubmit = async (values: any) => {
    setLoadingOrder(true);
    try {
      const retrievedOrder = await OrderService.fetchOrderByNumber(values.orderNumber, values.orderEmail);
      setOrder(retrievedOrder.order as OrderData, retrievedOrder.account as AccountData);
    } catch (e: any) {
      setNotFound();
    }
    setLoadingOrder(false);
  };
  const validationSchema = Yup.object({
    orderNumber: Yup.number().required(t('manage_booking.orderNumberRequired')),
    orderEmail: Yup.string().required(t('manage_booking.orderEmailRequired')).email(t('manage_booking.invalidEmail')),
  });
  return (
    <Formik
      enableReinitialize
      initialValues={{
        orderNumber: '',
        orderEmail: '',
      }}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({ errors, values, handleChange, handleBlur }) => (
        <Form style={{ height: '100%' }}>
          <Grid
            container
            flexDirection="column"
            gap={4}
            alignItems="center"
            minHeight="430px"
            justifyContent="space-between">
            <Grid container flexDirection="column" alignItems="center">
              <Grid item mb={4}>
                <img
                  alt="powered by Monkeybook"
                  className="logo"
                  src={'/images/logos/monkeybook-light.png'}
                  width="250px"
                />
              </Grid>
              <Grid item gap={0} my={2}>
                <OrderPageTextField
                  value={values.orderNumber}
                  onBlur={handleBlur}
                  type="number"
                  name="orderNumber"
                  size="small"
                  fullWidth
                  variant="outlined"
                  label={t('manage_booking.orderNumber')}
                  error={!!errors.orderNumber}
                  onChange={handleChange}
                />
                {!!errors.orderNumber && (
                  <OrderPageTypography variant="body2">{errors.orderNumber}</OrderPageTypography>
                )}
              </Grid>
              <Grid item gap={0} mb={2} mt={errors.orderNumber ? 0 : 2}>
                <OrderPageTextField
                  value={values.orderEmail}
                  type="text"
                  onBlur={handleBlur}
                  name="orderEmail"
                  size="small"
                  fullWidth
                  variant="outlined"
                  label={t('manage_booking.orderEmail')}
                  error={!!errors.orderEmail}
                  onChange={handleChange}
                />
                {!!errors.orderEmail && <OrderPageTypography variant="body2">{errors.orderEmail}</OrderPageTypography>}
              </Grid>
            </Grid>
            <OrderPageButton type="submit" loading={loadingOrder} variant="outlined">
              <OrderPageTypography>{t('manage_booking.submitdetails')}</OrderPageTypography>
            </OrderPageButton>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
