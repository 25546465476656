// src/experienceSettings/formComponents/FormCheckbox.tsx
import { Field, FieldProps } from 'formik';
import React from 'react';
import { Checkbox, FormControlLabel, Grid } from '@mui/material';
import Typography from '@mui/material/Typography';
import { StyledErrorMessage } from '../StyledErrorMessage';
import { FormElementProps, getShouldMarkFieldAsUpdated } from './utils';

type FormCheckboxProps = FormElementProps & {
  indeterminate?: boolean;
};
export const FormCheckbox = ({ label, fieldName, disabled, onChange, isFieldUpdate }: FormCheckboxProps) => {
  return (
    <Grid container alignItems="center">
      <Grid item>
        <Field name={fieldName}>
          {({ field, form }: FieldProps<boolean>) => {
            const shouldMarkFieldAsUpdated = getShouldMarkFieldAsUpdated(form, fieldName, field, isFieldUpdate);
            return (
              <FormControlLabel
                key={`${fieldName}-checkbox`}
                control={
                  <Checkbox
                    {...field}
                    checked={field.value}
                    disabled={disabled}
                    onChange={(e) => {
                      if (onChange) {
                        onChange(e);
                        return;
                      }
                      void form.setFieldValue(field.name, e.target.checked);
                    }}
                    color="primary"
                    sx={{
                      '&& .MuiCheckbox-root': {
                        borderColor: shouldMarkFieldAsUpdated ? '#47b406' : undefined,
                        borderWidth: shouldMarkFieldAsUpdated ? '4px' : undefined,
                      },
                      '&& .MuiCheckbox-root.Mui-checked': {
                        borderColor: shouldMarkFieldAsUpdated ? '#47b406' : undefined,
                        borderWidth: shouldMarkFieldAsUpdated ? '4px' : undefined,
                      },
                    }}
                  />
                }
                label={
                  <Typography
                    color={shouldMarkFieldAsUpdated ? '#47b406' : undefined}
                    fontWeight={shouldMarkFieldAsUpdated ? 700 : undefined}>
                    {label}
                  </Typography>
                }
              />
            );
          }}
        </Field>
        <StyledErrorMessage name={fieldName} />
      </Grid>
    </Grid>
  );
};
