import { FormControl, Grid, MenuItem, Select } from '@mui/material';
import React from 'react';
import { useLanguage } from '../../../../../common/GeneralUtils';
import { WorkshopCircle } from '../../../../../common/ui/WorkshopCircle';
import { useAuth } from '../../../../../session/InternalAuthProvider';
import { AccountData } from '../../../../../api';

// @ts-ignore
export const ChooseWorkshopSelect = ({ orderPages, editOrderState, setOrderPage, setExpandedPanel }) => {
  const language = useLanguage();
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  return (
    <FormControl>
      <Select
        value={editOrderState.orderPage.id}
        renderValue={() => (
          <Grid container alignItems="center" gap={1}>
            <WorkshopCircle account={account} orderPageId={editOrderState.orderPage.id} language={language} />
            <Grid item>{editOrderState.orderPage.label[language]}</Grid>
          </Grid>
        )}
        onChange={(event) => {
          setOrderPage(event.target.value);
          setExpandedPanel(undefined);
        }}>
        {orderPages.map((x: any) => (
          <MenuItem key={`ChooseWorkshopSelect-${x.id}`} value={x.id}>
            <Grid container alignItems="center" gap={1}>
              <WorkshopCircle account={account} orderPageId={x.id} language={language} />
              {x.label[language]}
            </Grid>
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  );
};
