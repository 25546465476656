import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import React from 'react';
import { Form, Formik } from 'formik';
import { omit } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import { KeyedMutator } from 'swr/_internal';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import { AccountData, AccountService, OrderPageData } from '../../../api';
import { OrderPagePrice, OrderPageUpdate } from '../../OrderPageSettingsPage';
import { UseAuthPermissions } from '../../../session/UseAuthPermissions';
import { FormTextField } from '../../formComponents/FormTextField';
import { getCurrencySymbol } from '../../../order_page/utils';
import { useAuth } from '../../../session/InternalAuthProvider';

type PricingSectionProps = {
  orderPage: OrderPageData;
  orderPageInitialValues: OrderPageUpdate;
  updateLocalAccount: KeyedMutator<AccountData>;
  setIsUpdatedSnackOpen: (value: boolean) => void;
};

export type PricingFormValues = {
  price: OrderPagePrice[];
};

export const PricingSection = ({
  orderPage,
  orderPageInitialValues,
  updateLocalAccount,
  setIsUpdatedSnackOpen,
}: PricingSectionProps) => {
  const { t } = useTranslation();
  const { authState } = useAuth();

  const { readOnly } = UseAuthPermissions();
  const posthog = usePostHog();
  const currencySymbol = getCurrencySymbol(authState.account as unknown as AccountData);
  const validationSchema = yup.object().shape({});

  const initialValues: PricingFormValues = {
    price: orderPage.price as OrderPagePrice[],
  };

  const handleSubmit = async (values: PricingFormValues) => {
    try {
      posthog.capture('Editing settings', { section: 'pricingSection', settings: values });
      const updatedAccount = await AccountService.updateOrderPage(
        omit(
          {
            ...orderPageInitialValues,
            duration: (orderPageInitialValues.duration || 0) * 60,
            ...values,
            id: orderPage.id,
          },
          'availability_settings.restrictions',
          'objects',
        ),
      );
      await updateLocalAccount(updatedAccount);
      setIsUpdatedSnackOpen(true);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({ values, isSubmitting, dirty, handleSubmit }) => {
        const formIsDisabled = readOnly || isSubmitting;
        return (
          <Form onSubmit={handleSubmit}>
            <Grid container justifyContent="space-between" flexWrap="nowrap" alignItems="flex-start">
              <Grid container flexDirection="column" gap={1}>
                {values.price.map((price, i) => {
                  if (price.type === 'number' && price.data_type === 'persons') {
                    return (
                      <Grid container alignItems="center">
                        <Grid container alignItems="center">
                          <Grid item>
                            <Typography>{t('editOrderPage.basePrice')}</Typography>
                          </Grid>
                          <Grid item mx={1}>
                            <FormTextField
                              type="number"
                              isFieldUpdate
                              width={100}
                              fieldName={`price[${i}].base_price`}
                              disabled={formIsDisabled}
                              label={''}
                              endAdornment={currencySymbol}
                            />
                          </Grid>
                          <Typography>{t('editOrderPage.for')}</Typography>
                          <Grid item mx={1}>
                            <FormTextField
                              type="number"
                              isFieldUpdate
                              width={50}
                              fieldName={`price[${i}].base_value`}
                              disabled={formIsDisabled}
                              label={''}
                            />
                          </Grid>
                          <Grid item>
                            <Typography>
                              {price.base_value && price.base_value > 1
                                ? t('editOrderPage.persons')
                                : t('editOrderPage.person')}
                            </Typography>
                          </Grid>
                        </Grid>
                        <Grid container alignItems="center" mt={1}>
                          <Typography>{t('editOrderPage.forAdditional')}</Typography>
                          <Grid item mx={1}>
                            <FormTextField
                              type="number"
                              isFieldUpdate
                              width={100}
                              fieldName={`price[${i}].price_per_additional_value`}
                              disabled={formIsDisabled}
                              endAdornment={currencySymbol}
                              label={''}
                            />
                          </Grid>
                        </Grid>
                      </Grid>
                    );
                  }

                  return (
                    <Grid>
                      <Typography>{t('editOrderPage.notSupported')}</Typography>
                    </Grid>
                  );
                })}
              </Grid>
              <LoadingButton
                size="large"
                loading={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
                disabled={!dirty || formIsDisabled}>
                {t('editOrderPage.orderPageBufferSubmit')}
              </LoadingButton>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
