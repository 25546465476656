import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useLanguage } from '../common/GeneralUtils';

export const useLanguageChange = (accountDefaultLanguage: string | undefined) => {
  const { i18n } = useTranslation();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const language = useLanguage();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const swapLanguage = async () => {
      if (accountDefaultLanguage) {
        if (accountDefaultLanguage !== language && !queryParams.get('language') && !queryParams.get('langSwapped')) {
          await i18n.changeLanguage(accountDefaultLanguage);
        }
        setIsLoading(false);
      }
    };
    void swapLanguage();
  }, [accountDefaultLanguage, language, queryParams, i18n]);

  return { isLoading };
};
