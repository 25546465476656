import { createContext, useContext } from 'react';
import { orderPageInitialState, OrderPageState } from './OrderPageState';

export type OrderPageContextType = {
  reloadDates: () => void;
  orderPageState: OrderPageState;
  setOrderDetailValue: (dataType: string, value: any) => void;
  setCustomOrderDetailValue: (name: string, value: any) => void;
  setOrderCustomDetails: (customDetails: Record<string, any>) => void;
  setOrderPageState: (val: OrderPageState) => void;
  setPersonalDetails: (personalDetails: Record<string, any>) => void;
  setDate: (date: any) => void;
  setTime: (time: any) => void;
  setPaymentUrl: (val: string) => void;
  setActiveStep: (val: number) => void;
  setActiveStepAndOrderPageIntent: (activeStep: number, orderPageIntent: string) => void;
  setTip: (type: string, value: number) => void;
};
export const OrderPageContext = createContext<OrderPageContextType>({
  reloadDates: () => {},
  orderPageState: orderPageInitialState,
  setOrderDetailValue: (_dataType: string, _value: any) => {},
  setCustomOrderDetailValue: (_name: string, _value: any) => {},
  setOrderCustomDetails: (_customDetails: Record<string, any>) => {},
  setOrderPageState: (_val: OrderPageState) => {},
  setPersonalDetails: (_personalDetails: Record<string, any>) => {},
  setDate: (_date: any) => {},
  setTime: (_time: any) => {},
  setPaymentUrl: (_val: string) => {},
  setActiveStep: (_val: number) => {},
  setActiveStepAndOrderPageIntent: (_activeStep: number, _orderPageIntent: string) => {},
  setTip: (_type: string, _value: number) => {},
});

export const useOrderPage = () => useContext(OrderPageContext);
