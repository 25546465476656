/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateLinkInput } from '../models/CreateLinkInput';
import type { LinkResponseDTO } from '../models/LinkResponseDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LinksService {
  /**
   * Create Link
   * @param requestBody
   * @returns LinkResponseDTO Successful Response
   * @throws ApiError
   */
  public static createShortLink(requestBody: CreateLinkInput): CancelablePromise<LinkResponseDTO> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/links',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
