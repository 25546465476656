import { useEffect, useState } from 'react';

export interface CalendarLocalPreferences {
  shouldShowUnavailability: boolean;
  shouldShowOnlyCurrentUser: boolean;
  shouldShowShifts: boolean;
  shouldShowUserAvailabilities: boolean;
  shouldShowAvailability: boolean;
}

const defaultPreferences: CalendarLocalPreferences = {
  shouldShowUnavailability: true,
  shouldShowOnlyCurrentUser: false,
  shouldShowShifts: true,
  shouldShowUserAvailabilities: true,
  shouldShowAvailability: true,
};

const loadPreferences = (): CalendarLocalPreferences => {
  const storedPreferences = localStorage.getItem('calendar_local_preferences');
  return storedPreferences ? JSON.parse(storedPreferences) : defaultPreferences;
};

const savePreferences = (preferences: CalendarLocalPreferences): void => {
  localStorage.setItem('calendar_local_preferences', JSON.stringify(preferences));
};

export const useCalendarLocalPreferences = (): {
  preferences: CalendarLocalPreferences;
  toggleShowUnavailability: () => void;
  toggleShouldShowShifts: () => void;
  toggleShouldShowOnlyCurrentUser: () => void;
  toggleShowUserUnavailability: () => void;
  toggleShowAvailability: () => void;
} => {
  const [preferences, setPreferences] = useState<CalendarLocalPreferences>(loadPreferences);

  useEffect(() => {
    savePreferences(preferences);
  }, [preferences]);

  const toggleShouldShowShifts = () => {
    setPreferences({
      ...preferences,
      shouldShowShifts: !preferences.shouldShowShifts,
    });
  };
  const toggleShouldShowOnlyCurrentUser = () => {
    setPreferences({
      ...preferences,
      shouldShowOnlyCurrentUser: !preferences.shouldShowOnlyCurrentUser,
    });
  };
  const toggleShowUnavailability = () => {
    setPreferences({
      ...preferences,
      shouldShowUnavailability: !preferences.shouldShowUnavailability,
    });
  };
  const toggleShowUserUnavailability = () => {
    setPreferences({
      ...preferences,
      shouldShowUserAvailabilities: !preferences.shouldShowUserAvailabilities,
    });
  };
  const toggleShowAvailability = () => {
    setPreferences({
      ...preferences,
      shouldShowAvailability: !preferences.shouldShowAvailability,
    });
  };
  return {
    preferences,
    toggleShowUnavailability,
    toggleShouldShowOnlyCurrentUser,
    toggleShouldShowShifts,
    toggleShowUserUnavailability,
    toggleShowAvailability,
  };
};
