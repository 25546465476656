import Grid from '@mui/material/Grid';
import moment from 'moment';
import { useAuth } from '../../../session/InternalAuthProvider';
import { AccountData } from '../../../api';
import { getTimeFormat } from '../../../common/getDateFormat';

type FinalDateDetailsProps = {
  date: moment.Moment;
  timeSeconds: number;
  duration: number;
};
const FinalDateDetails = ({ date, timeSeconds, duration }: FinalDateDetailsProps) => {
  const endTimeSeconds = timeSeconds + duration;
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const timeFormat = getTimeFormat(account);
  return (
    <Grid container gap={2}>
      <Grid item>{date.format('dddd, MMMM, DD')}</Grid>
      <Grid item dir="ltr">
        {moment.unix(timeSeconds).tz('utc').format(timeFormat)} -{' '}
        {moment.unix(endTimeSeconds).tz('utc').format(timeFormat)}
      </Grid>
    </Grid>
  );
};

export default FinalDateDetails;
