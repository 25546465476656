import { Box, ClickAwayListener, Fade, Grid, Icon, Paper, Stack, Tooltip, Typography } from '@mui/material';
import React, { useState } from 'react';
import {
  AttachMoney,
  Close,
  CopyAll,
  Delete,
  Edit,
  Engineering,
  Event,
  KeyboardArrowLeft,
  KeyboardArrowRight,
  Loop,
  Numbers,
  People,
  Percent,
  PriorityHigh,
  Schedule,
  ShoppingCart,
  Subject,
  Title,
  Update,
  WhereToVote,
} from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import './OrderBoxPopper.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import TimeUtils from '../../common/TimeUtils';
import { useCalendar } from '../CalendarContext';
import ResponsivePopper from '../../common/ui/ResponsivePopper';
import { useAuth } from '../../session/InternalAuthProvider';
import { getOrderPage } from '../../common/AccountUtils';
import { useLanguage } from '../../common/GeneralUtils';
import { AccountData, OrderData } from '../../api';
import { UseAuthPermissions } from '../../session/UseAuthPermissions';
import { whatsAppLinkFormatter } from '../../common/whatsAppLinkFormatter';
import { getEmployeeNames } from '../CalendarUtils';
import CheckinDialog from '../dialogs/CheckinDialog/CheckinDialog';
import { useOrders } from '../swr/useOrders';
import CopyToClipboardButton from '../dialogs/CopyToClipboardButton';
import { usePriceRenderer } from '../../common/usePriceRenderer';
import { WorkshopCircle } from '../../common/ui/WorkshopCircle';
import { getTimeFormat } from '../../common/getDateFormat';

type OrderBoxPopperProps = {
  anchorEl: any;
  closeOrderBoxPopper: () => void;
  order: OrderData;
  getNextOrder: () => OrderData | undefined;
  getPreviousOrder: () => OrderData | undefined;
  openOrder: (event: any, order: OrderData) => void;
};
const OrderBoxPopper = ({
  anchorEl,
  closeOrderBoxPopper,
  order,
  getNextOrder,
  getPreviousOrder,
  openOrder,
}: OrderBoxPopperProps) => {
  const { readOnly } = UseAuthPermissions();
  const selfServeEditsEnabled = useFeatureFlagEnabled('self-serve-edits');
  // in order to allow live updates on checking as order is a saparate use state and not part of useOrders its not
  // getting updated. a bit hacky solution but we will live with it for now
  const [internalOrder, setInternalOrder] = useState(order);
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  // @ts-ignore
  const { openEditOrderDialog, openDeleteOrderDialog, users, startDate, endDate } = useCalendar();
  const { updateItem: updateOrder } = useOrders({ startDate, endDate });

  // @ts-ignore
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const timeFormat = getTimeFormat(account);

  const [checkInDialogIsOpen, setCheckInDialogIsOpen] = useState(false);
  const language = useLanguage();
  const orderPage = getOrderPage(account, internalOrder.order_page_id);
  const { SlimPrice } = usePriceRenderer({
    orderId: internalOrder.id,
    discountId: internalOrder.discount_id,
    initialPrice: internalOrder.price,
    returningCustomerFromOrderId: internalOrder.returning_customer_from_order_id,
    extraPaymentLines: internalOrder.extra_payment_lines,
  });
  const element = document.querySelector('#calendar-container');

  const formatEmployees = () => {
    if (internalOrder.employee_ids.length) {
      return getEmployeeNames(internalOrder.employee_ids, users).map((x) => (
        <Grid key={`employee-${x}`} item color={'black'}>
          {x}
        </Grid>
      ));
    }
    return '';
  };
  const nextOrder = getNextOrder();
  const previousOrder = getPreviousOrder();

  return (
    <ResponsivePopper
      open={open}
      anchorEl={anchorEl}
      placement="left-start"
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            boundary: element,
            fallbackPlacements: ['left-start', 'left-end', 'right-start', 'right-end', 'top', 'bottom'],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            altAxis: true,
            padding: 10,
          },
        },
      ]}>
      <Fade in timeout={520}>
        <div className="height-100">
          <ClickAwayListener
            onClickAway={() => {
              if (!checkInDialogIsOpen) {
                closeOrderBoxPopper();
              }
            }}>
            <Paper elevation={24} className="order-box-popper-content height-100">
              <Stack direction="row" justifyContent="flex-end" padding="2px">
                <Grid
                  container
                  flexDirection={language === 'he' ? 'row-reverse' : 'row-reverse'}
                  justifyContent={'flex-end'}>
                  <Tooltip title={t('next')} arrow>
                    <span>
                      <IconButton disabled={!nextOrder} onClick={() => openOrder(undefined, nextOrder!)}>
                        {language === 'he' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                      </IconButton>
                    </span>
                  </Tooltip>
                  <Tooltip title={t('previous')} arrow>
                    <span>
                      <IconButton disabled={!previousOrder} onClick={() => openOrder(undefined, previousOrder!)}>
                        {language === 'he' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                      </IconButton>
                    </span>
                  </Tooltip>
                </Grid>
                <IconButton onClick={() => openEditOrderDialog(order, null, null, null, null, null, null, true)}>
                  <Tooltip title={t('cloneOrder')} arrow>
                    <CopyAll />
                  </Tooltip>
                </IconButton>
                <IconButton
                  onClick={() => {
                    setCheckInDialogIsOpen(true);
                  }}>
                  <Tooltip title={t('checkin')} arrow>
                    <WhereToVote />
                  </Tooltip>
                </IconButton>
                <a
                  href={
                    !readOnly && selfServeEditsEnabled
                      ? `${window.location.origin}/manage-booking?orderid=${internalOrder.id}`
                      : undefined
                  }
                  target="_blank"
                  rel="noopener noreferrer">
                  <Tooltip
                    title={readOnly ? t('noPermissions') : selfServeEditsEnabled ? t('amend') : t('amend_not_enabled')}
                    arrow>
                    <span>
                      <IconButton disabled={readOnly}>
                        <Update />
                      </IconButton>
                    </span>
                  </Tooltip>
                </a>
                <Tooltip title={readOnly ? t('noPermissions') : t('delete')} arrow>
                  <span>
                    <IconButton
                      disabled={readOnly}
                      onClick={() => {
                        openDeleteOrderDialog(internalOrder);
                        closeOrderBoxPopper();
                      }}>
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={readOnly ? t('noPermissions') : t('edit')} arrow>
                  <span>
                    <IconButton
                      disabled={readOnly}
                      onClick={() => {
                        openEditOrderDialog(internalOrder);
                        closeOrderBoxPopper();
                      }}>
                      <Edit />
                    </IconButton>
                  </span>
                </Tooltip>
                <IconButton onClick={closeOrderBoxPopper}>
                  <Tooltip title={t('close')} arrow>
                    <Close />
                  </Tooltip>
                </IconButton>
              </Stack>

              <Box className="details-container">
                <Box className="detail-container">
                  <WorkshopCircle account={account} orderPageId={order.order_page_id} language={language} />
                  <Box className="name-container" alignItems="center" display="flex">
                    {internalOrder.returning_customer && (
                      <Tooltip title={t('returnningCustomer')} arrow>
                        <Loop style={{ color: 'green' }} />
                      </Tooltip>
                    )}
                    {`${internalOrder.firstname} ${internalOrder.lastname}`}
                  </Box>
                </Box>

                <Box className="date-container">
                  {moment.unix(internalOrder.timestamp || 0).format('dddd, MMMM, DD')}
                  &nbsp;&nbsp;&nbsp;⋅&nbsp;&nbsp;&nbsp;
                  {moment
                    .unix(internalOrder.time || 0)
                    .tz('utc')
                    .format(timeFormat)}{' '}
                  -{' '}
                  {moment
                    .unix((internalOrder.time || 0) + internalOrder.duration)
                    .tz('utc')
                    .format(timeFormat)}
                </Box>

                <Box className="detail-container">
                  <div className="icon-container">
                    <Title />
                  </div>
                  <Box>{orderPage.label[language]}</Box>
                </Box>
                <Box className="detail-container">
                  <div className="icon-container">
                    <PriorityHigh style={{ color: 'orange' }} />
                  </div>
                  <Tooltip title={t('comment')} placement="top" arrow>
                    <Box maxWidth="300px">
                      <span>{internalOrder.comment}</span>
                    </Box>
                  </Tooltip>
                </Box>
                <Box className="detail-container">
                  <div className="icon-container">
                    <Engineering />
                  </div>
                  <Tooltip title={t('employees')} placement="top" arrow>
                    <Grid container item justifyContent="flex-start" flexWrap="nowrap" alignItems="center" gap={0.5}>
                      {formatEmployees()}
                    </Grid>
                  </Tooltip>
                </Box>
                {internalOrder.order_number != null && internalOrder.order_number !== 0 && (
                  <Box className="detail-container">
                    <div className="icon-container">
                      <Numbers />
                    </div>
                    <Tooltip title={t('order_number')} placement="top" arrow>
                      <Box>
                        <span>{t('order_number')}</span>
                        <span> &#35;</span>
                        <span>{internalOrder.order_number}</span>
                      </Box>
                    </Tooltip>
                  </Box>
                )}

                <Box className="detail-container">
                  <div className="icon-container">
                    <People />
                  </div>
                  <Box>
                    {internalOrder.persons} {t('calendar.order_popper.persons')}
                  </Box>
                </Box>

                <Box className="detail-container">
                  <div className="icon-container">
                    <Schedule />
                  </div>
                  <Tooltip title={t('calendar.order_popper.duration')} placement="top" arrow>
                    <Box>{TimeUtils.formatDuration(internalOrder.duration, t)}</Box>
                  </Tooltip>
                </Box>

                <Box className="detail-container">
                  <div className="icon-container">
                    <AttachMoney />
                  </div>
                  <SlimPrice />
                </Box>

                {internalOrder.custom_details &&
                  orderPage.custom_details.map((custom_detail: any, index: number) => {
                    return (
                      <Box key={index} className="detail-container">
                        <div className="icon-container">
                          <Icon>{custom_detail.icon}</Icon>
                        </div>
                        <Tooltip title={custom_detail.name} placement="top" arrow>
                          <Box>
                            {custom_detail.type === 'str' && internalOrder.custom_details[custom_detail.name]}

                            {custom_detail.type === 'location' && (
                              <a
                                className="link"
                                target="_blank"
                                href={`https://maps.google.com/?q=term=${
                                  internalOrder.custom_details[custom_detail.name]
                                }`}
                                rel="noreferrer">
                                {internalOrder.custom_details[custom_detail.name]}
                              </a>
                            )}
                            {custom_detail.type === 'bool' && (
                              <div>
                                {internalOrder.custom_details[custom_detail.name] ? (
                                  <span>{t('yes')}</span>
                                ) : (
                                  <span>{t('no')}</span>
                                )}
                              </div>
                            )}

                            {custom_detail.type === 'resourceInt' && (
                              <Typography>{internalOrder.custom_details[custom_detail.name]}</Typography>
                            )}
                          </Box>
                        </Tooltip>
                      </Box>
                    );
                  })}

                {internalOrder.custom_personal_details &&
                  orderPage.custom_personal_details.map((custom_detail: any, index: number) => {
                    if (internalOrder.custom_personal_details[custom_detail.id]) {
                      return (
                        <Box key={index} className="detail-container">
                          <div className="icon-container">
                            <Icon>note</Icon>
                          </div>
                          <Tooltip title={custom_detail.label[language]} placement="top" arrow>
                            <Box>
                              {custom_detail.type === 'multiline' && (
                                <span>{internalOrder.custom_personal_details[custom_detail.id]}</span>
                              )}
                            </Box>
                          </Tooltip>
                        </Box>
                      );
                    }
                    return undefined;
                  })}

                <Box className="detail-container multiple-details-container">
                  <div className="icon-container">
                    <Subject />
                  </div>
                  <Box className="multiple-details-container">
                    {t('calendar.order_popper.name')}: {internalOrder.firstname} {internalOrder.lastname}
                    <br />
                    <Grid container gap={1}>
                      {t('calendar.order_popper.email')}: {internalOrder.email}
                      <CopyToClipboardButton width={'18px'} color="grey" text={internalOrder.email} />
                    </Grid>
                    <br />
                    <Grid container gap={1}>
                      {t('calendar.order_popper.phone')}:
                      <a
                        className="link"
                        target="_blank"
                        href={whatsAppLinkFormatter(internalOrder.phone, '')}
                        rel="noreferrer">
                        {internalOrder.phone}
                      </a>
                      <CopyToClipboardButton width={'18px'} color="grey" text={internalOrder.phone} />
                    </Grid>
                  </Box>
                </Box>

                <Tooltip title={t('calendar.order_popper.creation_time')} placement="top" arrow>
                  <Box className="detail-container">
                    <div className="icon-container">
                      <Event />
                    </div>
                    <Box>
                      {TimeUtils.getLocalTime(internalOrder.created_at, account.timezone).format('dddd, MMMM, DD')}
                      &nbsp;&nbsp;&nbsp;⋅&nbsp;&nbsp;&nbsp;
                      {TimeUtils.getLocalTime(internalOrder.created_at, account.timezone).format(timeFormat)}
                    </Box>
                  </Box>
                </Tooltip>

                {account.returning_customer_settings && (
                  <Box className="detail-container">
                    <div className="icon-container">
                      <Percent />
                    </div>
                    <Grid container flexDirection="column">
                      <Typography>{t('calendar.order_popper.returningCsutomer')}</Typography>
                      <Grid container gap={1} alignItems="center">
                        {internalOrder.id}
                        <CopyToClipboardButton width={'18px'} color="grey" text={internalOrder.id} />
                      </Grid>
                    </Grid>
                  </Box>
                )}
                {internalOrder.checkin && (
                  <Box className="detail-container">
                    <div className="icon-container">
                      <WhereToVote />
                    </div>
                    <Tooltip title={t('calendar.order_popper.checkin')} placement="top" arrow>
                      <Box
                        sx={{
                          textTransform: 'capitalize',
                        }}>{`${internalOrder.checkin?.persons}/${internalOrder.persons}`}</Box>
                    </Tooltip>
                  </Box>
                )}

                <Box className="detail-container">
                  <div className="icon-container">
                    <ShoppingCart />
                  </div>
                  <Tooltip title={t('calendar.order_popper.status')} placement="top" arrow>
                    <Box sx={{ textTransform: 'capitalize' }}>
                      {t(`order_status.${internalOrder.status.toLowerCase()}`)}
                    </Box>
                  </Tooltip>
                </Box>
              </Box>
            </Paper>
          </ClickAwayListener>
        </div>
      </Fade>
      <CheckinDialog
        order={internalOrder}
        isOpen={checkInDialogIsOpen}
        setIsOpen={setCheckInDialogIsOpen}
        updateItem={(itemToUpdate: OrderData) => {
          void updateOrder(itemToUpdate);
          setInternalOrder(itemToUpdate);
        }}
      />
    </ResponsivePopper>
  );
};

export default OrderBoxPopper;
