import { Field, FieldProps } from 'formik';
import React from 'react';
import { Grid } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { StyledErrorMessage } from '../StyledErrorMessage';
import { AccountData } from '../../api';
import { useAuth } from '../../session/InternalAuthProvider';
import { getDateFormat } from '../../common/getDateFormat';
import { DATE_FORMAT } from '../../calendar/CalendarWrapper';
import { FormElementProps, getShouldMarkFieldAsUpdated } from './utils';

export const FormDatePicker = ({ disabled, fieldName, label, onChange, isFieldUpdate }: FormElementProps) => {
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const { longDateFormat } = getDateFormat(account);
  return (
    <Grid container justifyContent="flex-start">
      <Grid item>
        <Field name={fieldName}>
          {({ field, form }: FieldProps<string>) => {
            const shouldMarkFieldAsUpdated = getShouldMarkFieldAsUpdated(form, fieldName, field, isFieldUpdate);
            return (
              <DatePicker
                {...field}
                sx={{
                  '&& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    borderColor: shouldMarkFieldAsUpdated ? '#47b406' : undefined,
                    borderWidth: shouldMarkFieldAsUpdated ? '4px' : undefined,
                  },
                  '&& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: shouldMarkFieldAsUpdated ? '#47b406' : undefined,
                    borderWidth: shouldMarkFieldAsUpdated ? '4px' : undefined,
                  },
                }}
                value={moment(field.value)}
                disabled={disabled}
                onChange={(newValue) => {
                  onChange?.(newValue);
                  void form.setFieldValue(fieldName, newValue!.format(DATE_FORMAT));
                }}
                disablePast
                format={longDateFormat}
                label={label}
              />
            );
          }}
        </Field>
        <StyledErrorMessage name={fieldName} />
      </Grid>
    </Grid>
  );
};
