import { Field, FieldProps } from 'formik';
import React from 'react';
import { FormControlLabel, Grid, Switch } from '@mui/material';
import Typography from '@mui/material/Typography';
import { StyledErrorMessage } from '../StyledErrorMessage';
import { FormElementProps, getShouldMarkFieldAsUpdated } from './utils';

export const FormSwitch = ({ label, fieldName, disabled, onChange, isFieldUpdate }: FormElementProps) => {
  return (
    <Grid container alignItems="center">
      <Grid item>
        <Field name={fieldName}>
          {({ field, form }: FieldProps<boolean>) => {
            const shouldMarkFieldAsUpdated = getShouldMarkFieldAsUpdated(form, fieldName, field, isFieldUpdate);
            return (
              <FormControlLabel
                control={
                  <Switch
                    {...field}
                    checked={field.value}
                    disabled={disabled}
                    onChange={(e) => (onChange ? onChange(e) : form.setFieldValue(field.name, e.target.checked))}
                    color="primary"
                  />
                }
                label={
                  <Typography
                    color={shouldMarkFieldAsUpdated ? '#47b406' : undefined}
                    fontWeight={shouldMarkFieldAsUpdated ? 700 : undefined}>
                    {label}
                  </Typography>
                }
              />
            );
          }}
        </Field>
        <StyledErrorMessage name={fieldName} />
      </Grid>
    </Grid>
  );
};
