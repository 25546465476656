/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PrivateSlotCreateDTO } from '../models/PrivateSlotCreateDTO';
import type { PrivateSlotOut } from '../models/PrivateSlotOut';
import type { PrivateSlotRecurrentUpdate } from '../models/PrivateSlotRecurrentUpdate';
import type { PrivateSlotUpdate } from '../models/PrivateSlotUpdate';
import type { RecurrentPrivateSlotCreateDTO } from '../models/RecurrentPrivateSlotCreateDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PrivateSlotsAvailabilityService {
  /**
   * Edit Private Slot
   * @param requestBody
   * @returns PrivateSlotOut Successful Response
   * @throws ApiError
   */
  public static updatePrivateSlot(requestBody: PrivateSlotUpdate): CancelablePromise<PrivateSlotOut> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/private-slots-availability',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Private Slot
   * @param requestBody
   * @returns PrivateSlotOut Successful Response
   * @throws ApiError
   */
  public static createPrivateSlot(requestBody: PrivateSlotCreateDTO): CancelablePromise<PrivateSlotOut> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/private-slots-availability',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Private Slot
   * @param privateSlotId
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deletePrivateSlot(privateSlotId: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/private-slots-availability',
      query: {
        private_slot_id: privateSlotId,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Edit Repeating Private Slot
   * @param requestBody
   * @returns PrivateSlotOut Successful Response
   * @throws ApiError
   */
  public static updateRecurrentPrivateSlot(
    requestBody: PrivateSlotRecurrentUpdate,
  ): CancelablePromise<Array<PrivateSlotOut>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/private-slots-availability/repeating',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Create Repeating Private Slot
   * @param requestBody
   * @returns PrivateSlotOut Successful Response
   * @throws ApiError
   */
  public static createPrivateSlotRecurrent(
    requestBody: RecurrentPrivateSlotCreateDTO,
  ): CancelablePromise<Array<PrivateSlotOut>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/private-slots-availability/repeating',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        422: `Validation Error`,
      },
    });
  }

  /**
   * Delete Repeating Private Slot
   * @param recurrentPrivateSlotId
   * @param fromDate
   * @returns any Successful Response
   * @throws ApiError
   */
  public static deleteRecurrentPrivateSlot(recurrentPrivateSlotId: string, fromDate: string): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/private-slots-availability/repeating',
      query: {
        recurrent_private_slot_id: recurrentPrivateSlotId,
        from_date: fromDate,
      },
      errors: {
        422: `Validation Error`,
      },
    });
  }
}
