import styled from '@emotion/styled';
import { Grid, Typography, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { SlimEventData } from '../../api';
import { useLanguage } from '../../common/GeneralUtils';
import { useOrderPage } from '../orderPageContext/useOrderPage';
import { getTimeFormat } from '../../common/getDateFormat';

type SpecificEventSummaryProps = {
  specificEvent: SlimEventData;
};

const StyledImage = styled('img')({
  width: '100%',
  height: 'auto',
  maxWidth: '100%',
  borderRadius: '8px',
});
export const SpecificEventSummary = ({ specificEvent }: SpecificEventSummaryProps) => {
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { orderPageState } = useOrderPage();
  const { t } = useTranslation();
  const language = useLanguage();
  const timeFormat = getTimeFormat(orderPageState?.account || undefined);

  const format = language === 'he' ? 'DD MMMM,  yyyy' : 'MMMM DD, yyyy';
  return (
    <Grid container flexDirection="column" mt={4} gap={1} flexWrap="nowrap" alignItems="center">
      <Grid item xs={isSmallScreen ? 12 : 8}>
        <StyledImage
          src={orderPageState.orderPage.photo}
          alt="Experience cover"
          style={{ border: `1px solid ${theme.customTheme.palette.border}` }}
        />
      </Grid>
      <Typography variant={isSmallScreen ? undefined : 'h5'} fontWeight="800">
        {orderPageState.orderPage.description?.[language]}
      </Typography>
      <Grid item container gap={2} mt={3}>
        <Typography variant="h5" fontWeight="800">
          {t('order_page.date')}
        </Typography>
        <Typography variant="h5">{moment(specificEvent.date).format(format)}</Typography>
      </Grid>

      <Grid item container gap={2}>
        <Typography variant="h5" fontWeight="800">
          {t('order_page.hour')}
        </Typography>
        <Typography variant="h5">{moment.unix(specificEvent.time).tz('utc').format(timeFormat)}</Typography>
      </Grid>
      {specificEvent.tickets_left < 10 && (
        <Grid item container gap={2}>
          <Typography variant="h5" fontWeight="900" color="orange">
            {t('order_page.ticketsLeft', { ticketsLeft: specificEvent.tickets_left })}
          </Typography>
        </Grid>
      )}
    </Grid>
  );
};
