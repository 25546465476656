/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum EntityType {
  UNAVAILABILITY = 'unavailability',
  PRIVATE_SLOT = 'private_slot',
  NOTE = 'note',
  USER_AVAILABILITY = 'user_availability',
}
