import useSWR from 'swr';
import { OrderService } from '../../api';

const fetchAvailableDates = async (accountId: string, orderPageIds: string[], persons: number) => {
  return OrderService.getAvailableDatesMultipleWorkshops({
    account_id: accountId,
    order_page_ids: orderPageIds,
    persons,
  });
};

export const useAvailableDates = (accountId: string, orderPageIds: string[], persons: number) => {
  const { data, error } = useSWR(
    [accountId, orderPageIds, persons],
    () => fetchAvailableDates(accountId, orderPageIds, persons),
    {
      revalidateOnFocus: false,
      dedupingInterval: 0,
      refreshInterval: 0,
    },
  );

  return {
    availableDates: data || [],
    isLoading: !error && !data,
    isError: error,
  };
};
