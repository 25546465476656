import { styled } from '@mui/system';
import {
  Accordion as MuiAccordion,
  AccordionSummary as MuiAccordionSummary,
  Grid,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Typography from '@mui/material/Typography';

export enum AccordionPanelsNames {
  DateAndTimePanel,
  ExperienceDetailPanel,
  PersonalDetailsPanel,
  ManagementPanel,
  PaymentLinksPanel,
  AllOpen,
}

export const StyledAccordion = styled(MuiAccordion)`
  border: 1px solid rgba(0, 0, 0, 0.39);
  border-bottom-width: 0;
`;
export const StyledAccordionSummary = styled(MuiAccordionSummary)`
  & .MuiAccordionSummary-expandIconWrapper {
    transform: rotate(-180deg);
  }

  & .MuiAccordionSummary-expandIconWrapper.Mui-expanded {
    transform: rotate(0deg);
  }
`;

type AccordionProps = {
  expandedPanelName?: AccordionPanelsNames;
  panelName: AccordionPanelsNames;
  onChange: any;
  children: React.ReactNode;
};

export const Accordion = ({ expandedPanelName, panelName, onChange, children }: AccordionProps) => {
  return (
    <StyledAccordion
      expanded={expandedPanelName === panelName || expandedPanelName === AccordionPanelsNames.AllOpen}
      onChange={onChange(panelName)}>
      {children!}
    </StyledAccordion>
  );
};

type AccordionSummaryProps = {
  title?: string;
  summaryComponent?: React.ReactNode;
};
export const AccordionSummary = ({ title, summaryComponent }: AccordionSummaryProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <StyledAccordionSummary expandIcon={<ExpandMoreIcon />}>
      <Grid
        container
        flexDirection={isSmallScreen ? 'column' : 'row'}
        alignItems={isSmallScreen ? undefined : 'center'}
        justifyContent="space-between">
        <Typography variant="h6">{title}</Typography>
        {summaryComponent && (
          <Grid item mx={isSmallScreen ? 0 : 1} justifyContent="flex-end" color="#0000009e">
            {summaryComponent}
          </Grid>
        )}
      </Grid>
    </StyledAccordionSummary>
  );
};
