import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, ToggleButton, ToggleButtonGroup } from '@mui/material';
import Typography from '@mui/material/Typography';
import TitledDrawer from '../../../common/ui/TitledDrawer';
import { PrivateSlotOut, UnavailabilityData } from '../../../api';
import { UnavailabilityDialog } from './UnavailabilityDialog/UnavailabilityDialog';
import { PrivateSlotAvailabilityDialog } from './AvailabilityDialog/AvailabilityDialog';

export type PrivateExperienceAvailabilityDrawerWrapperProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  calendarStartDate: string;
  calendarEndDate: string;
  unavailability?: UnavailabilityData;
  privateSlotAvailability?: PrivateSlotOut;
};

enum SelectionType {
  Availability = 'availability',
  Unavailability = 'unavailability',
}

export const PrivateExperienceAvailabilityDrawerWrapper = ({
  open,
  setOpen,
  unavailability,
  calendarStartDate,
  calendarEndDate,
  privateSlotAvailability,
}: PrivateExperienceAvailabilityDrawerWrapperProps) => {
  const { t } = useTranslation();
  const isUpdatingUnavailability = !!unavailability;
  const isUpdatingAvailability = !!privateSlotAvailability;
  const shouldHideOptions = isUpdatingUnavailability || isUpdatingAvailability;
  const [selection, setSelection] = useState<SelectionType>(
    isUpdatingAvailability ? SelectionType.Availability : SelectionType.Unavailability,
  );

  const handleClose = () => {
    setOpen(false);
  };

  const handleSelectionChange = (event: React.MouseEvent<HTMLElement>, newSelection: SelectionType | null) => {
    if (newSelection !== null) {
      setSelection(newSelection);
    }
  };

  const title = isUpdatingUnavailability
    ? t('calendar.unavailability_dialog.edit_un_title')
    : privateSlotAvailability
    ? t('calendar.unavailability_dialog.edit_title')
    : t('calendar.unavailability_dialog.title');

  return (
    <TitledDrawer onClose={handleClose} open={open} title={title}>
      <Grid container flexDirection="column" height={'100%'}>
        {!shouldHideOptions && (
          <Grid container p={3} mt={2}>
            <ToggleButtonGroup
              value={selection}
              exclusive
              onChange={handleSelectionChange}
              aria-label="availability selection">
              <ToggleButton value={SelectionType.Availability} aria-label="availability">
                <Typography variant={'h6'}>{t('calendar.unavailability_dialog.title')}</Typography>
              </ToggleButton>
              <ToggleButton value={SelectionType.Unavailability} aria-label="unavailability">
                <Typography variant={'h6'}> {t('calendar.privateSlotAvailabilityDialog.unavliability')}</Typography>
              </ToggleButton>
            </ToggleButtonGroup>
          </Grid>
        )}

        <Grid style={{ height: `calc(100% - ${shouldHideOptions ? 0 : '200px'})`, overflow: 'auto' }}>
          {selection === SelectionType.Unavailability ? (
            <UnavailabilityDialog
              setOpen={setOpen}
              unavailability={unavailability}
              calendarStartDate={calendarStartDate}
              calendarEndDate={calendarEndDate}
            />
          ) : (
            <PrivateSlotAvailabilityDialog
              privateSlotAvailability={privateSlotAvailability}
              setOpen={setOpen}
              calendarStartDate={calendarStartDate}
              calendarEndDate={calendarEndDate}
            />
          )}
        </Grid>
      </Grid>
    </TitledDrawer>
  );
};
