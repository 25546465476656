import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { Alert, Checkbox, Divider, FormControlLabel, Grid, Snackbar } from '@mui/material';
import Typography from '@mui/material/Typography';
import { LoadingButton } from '@mui/lab';
import { Form, Formik } from 'formik';
import { isEqual, isNil } from 'lodash';
import { AccountService, EmailTemplatesSettingsUpdateData, EmailTiming } from '../../api';
import { UseAuthPermissions } from '../../session/UseAuthPermissions';
import { FormTextField } from '../../experienceSettings/formComponents/FormTextField';
import { useGetAccountAuthenticated } from '../../session/swr/useGetAccountAuthenticated';
import { FormFieldColorPicker } from '../../experienceSettings/formComponents/FormFieldColorPicker';

export const EditEmailSettings = () => {
  const { readOnly } = UseAuthPermissions();
  const { account, updateLocalAccount } = useGetAccountAuthenticated({});
  const [isUpdatedSnackOpen, setIsUpdatedSnackOpen] = useState(false);

  const { t } = useTranslation();

  const emailTiming = account.email_timing;

  const [isSubmitting, setIsSubmitting] = useState<boolean>(false);
  const isDisabled = readOnly || isSubmitting;

  const initialValues: EmailTemplatesSettingsUpdateData = {
    pre_workshop_days: emailTiming?.pre_workshop_days,
    post_workshop_days: emailTiming?.post_workshop_days,
    border_color: account.email_communication_settings?.border_color,
  };
  const onSubmit = async (values: EmailTiming) => {
    setIsSubmitting(true);
    try {
      const updatedAccount = await AccountService.updateEmailTemplatesSettings(values);
      await updateLocalAccount(updatedAccount);
      setIsUpdatedSnackOpen(true);
    } catch (error) {
      console.error('Error saving settings:', error);
    } finally {
      setIsSubmitting(false);
    }
  };
  return (
    <Formik enableReinitialize initialValues={initialValues} onSubmit={onSubmit}>
      {({ values, handleSubmit, setFieldValue, submitForm }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container justifyContent="center" alignItems="center" flexDirection="column">
            <Snackbar
              open={isUpdatedSnackOpen}
              onClose={() => setIsUpdatedSnackOpen(false)}
              autoHideDuration={3000}
              anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <Alert
                sx={{ width: '100%' }}
                onClose={() => setIsUpdatedSnackOpen(false)}
                severity="success"
                variant="filled">
                {t('editOrderPage.orderPageTypeSuccess')}
              </Alert>
            </Snackbar>
            <Grid item>
              <Typography variant="h4">{t('emailTemplates.timingSettings')}</Typography>
            </Grid>
            <Grid container mt={2} gap={2} alignItems="center">
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="pre_workshop_days"
                      checked={!isNil(values.pre_workshop_days)}
                      onChange={(e, checked) => setFieldValue('pre_workshop_days', checked ? 7 : undefined)}
                    />
                  }
                  label={t('emailTemplates.enableDisablePreReminder')}
                />
              </Grid>
              <Grid item>
                <FormTextField
                  isFieldUpdate
                  type="number"
                  fieldName="pre_workshop_days"
                  width={80}
                  disabled={values.pre_workshop_days === undefined || isSubmitting}
                />
              </Grid>
              <Typography>{t('emailTemplates.preSendingTime')}</Typography>
            </Grid>
            <Grid container mt={2} gap={2} alignItems="center">
              <FormControlLabel
                control={
                  <Checkbox
                    name="post_workshop_days"
                    checked={!isNil(values.post_workshop_days)}
                    onChange={(e, checked) => setFieldValue('post_workshop_days', checked ? 1 : undefined)}
                  />
                }
                label={t('emailTemplates.enableDisablePostReminder')}
              />
              <Grid item>
                <FormTextField
                  isFieldUpdate
                  type="number"
                  fieldName="post_workshop_days"
                  width={80}
                  disabled={values.post_workshop_days === undefined || isSubmitting}
                />
              </Grid>
              <Typography>{t('emailTemplates.postSendingTime')}</Typography>
            </Grid>
            <Grid item my={6}>
              <Divider style={{ width: '300px' }} />
            </Grid>
            <Typography variant="h4">{t('emailTemplates.extraSettings')}</Typography>

            <FormFieldColorPicker
              tooltip={t('emailTemplates.borderColortooltip')}
              disabled={isDisabled}
              fieldName="border_color"
              label={t('editOrderPage.borderColor')}
            />
            <Grid item container gap={1} justifyContent="flex-end">
              <LoadingButton
                variant="contained"
                color="primary"
                onClick={submitForm}
                disabled={isDisabled || isEqual(values, initialValues)}
                loading={isSubmitting}>
                {t('emailTemplates.saveSettings')}
              </LoadingButton>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
