import { Box, ClickAwayListener, Fade, Grid, Paper, Stack, Tooltip } from '@mui/material';
import React from 'react';
import { Close, Delete, Edit } from '@mui/icons-material';
import IconButton from '@mui/material/IconButton';
import './UnavailabilityBoxPopper.scss';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { usePostHog } from 'posthog-js/react';
import { useAuthInfo } from '@propelauth/react';
import Typography from '@mui/material/Typography';
import { useCalendar } from '../CalendarContext';
import ResponsivePopper from '../../common/ui/ResponsivePopper';
import { AccountData, ShiftOut } from '../../api';
import { getEmployeeNames } from '../CalendarUtils';
import { UseAuthPermissions } from '../../session/UseAuthPermissions';
import { useAuth } from '../../session/InternalAuthProvider';
import { getTimeFormat } from '../../common/getDateFormat';

const ShiftBoxPopper = (props: { anchorEl: any; setAnchorEl: any; shift: ShiftOut }) => {
  const { anchorEl, setAnchorEl, shift } = props;
  const { readOnly } = UseAuthPermissions();
  const { user: loggedInUser } = useAuthInfo();
  const editDisabled = readOnly && loggedInUser?.userId !== shift.employee_id;
  const open = Boolean(anchorEl);
  const { t } = useTranslation();
  const { authState } = useAuth();

  const account = authState.account as unknown as AccountData;
  const timeFormat = getTimeFormat(authState?.account || undefined);

  // @ts-ignore
  const { openEditShiftDialog, openDeleteShiftDialog, users } = useCalendar();
  const posthog = usePostHog();

  const element = document.querySelector('#calendar-container');

  return (
    <ResponsivePopper
      open={open}
      anchorEl={anchorEl}
      placement="left-start"
      modifiers={[
        {
          name: 'flip',
          enabled: true,
          options: {
            boundary: element,
            fallbackPlacements: ['left-start', 'left-end', 'right-start', 'right-end', 'top', 'bottom'],
          },
        },
        {
          name: 'preventOverflow',
          options: {
            altAxis: true,
            padding: 10,
          },
        },
      ]}>
      <Fade in timeout={520}>
        <div className="height-100">
          <ClickAwayListener onClickAway={() => setAnchorEl(null)}>
            <Paper elevation={24} className="unavailability-box-popper-content height-100">
              <Stack direction="row" justifyContent="flex-end" padding="2px">
                <Tooltip title={readOnly ? t('noPermissions') : t('delete')} arrow>
                  <span>
                    <IconButton
                      disabled={editDisabled}
                      onClick={() => {
                        openDeleteShiftDialog(shift);
                        setAnchorEl(null);
                      }}>
                      <Delete />
                    </IconButton>
                  </span>
                </Tooltip>
                <Tooltip title={editDisabled ? t('noPermissions') : t('edit')} arrow>
                  <span>
                    <IconButton
                      disabled={editDisabled}
                      onClick={() => {
                        posthog.capture('Editing shift', { shift });
                        openEditShiftDialog(shift);
                        setAnchorEl(null);
                      }}>
                      <Edit />
                    </IconButton>
                  </span>
                </Tooltip>

                <IconButton onClick={() => setAnchorEl(null)}>
                  <Tooltip title={t('close')} arrow>
                    <Close />
                  </Tooltip>
                </IconButton>
              </Stack>

              <Box className="details-container">
                <Box className="detail-container">
                  <div
                    className="circle"
                    style={{ backgroundColor: account.shifts_as_employee_unavailability ? '#9A989872' : '#e0b06eff' }}
                  />
                  <Box>{`${
                    account.shifts_as_employee_unavailability ? t('calendar.shiftUnavliability') : t('calendar.shift')
                  }: ${getEmployeeNames([shift.employee_id], users)[0]}`}</Box>
                </Box>

                <Box className="date-container">
                  <Grid container flexDirection="row">
                    <Grid item>{moment(shift.date).format('dddd, MMMM, DD')}</Grid>
                    <Grid item mx={1}>
                      ⋅
                    </Grid>
                    <Grid item>
                      {moment
                        .unix(shift.start_time || 0)
                        .tz('utc')
                        .format(timeFormat)}{' '}
                      -{' '}
                    </Grid>
                    <Grid item>
                      {moment
                        .unix(shift.end_time || 0)
                        .tz('utc')
                        .format(timeFormat)}
                    </Grid>
                  </Grid>
                </Box>
                <Box className="date-container">
                  <Grid container flexDirection="row" gap={1}>
                    <Typography fontWeight={700}>{`${t('calendar.shift_dialog.description')}: `}</Typography>
                    <Typography>{shift.description}</Typography>
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </ClickAwayListener>
        </div>
      </Fade>
    </ResponsivePopper>
  );
};

export default ShiftBoxPopper;
