import { Box, Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useSearchParams } from 'react-router-dom';
import { usePostHog } from 'posthog-js/react';
import { OrderPageTypography } from '../../order_page/themedComponents/OrderPageTypography';
import { Language } from '../../api';
import { useLanguage } from '../GeneralUtils';

const MonkeybookWidgetFooter = ({
  languages = [],
  accountId = undefined,
}: {
  languages?: Language[];
  accountId?: string;
}) => {
  const posthog = usePostHog();
  const location = useLocation();

  const { t, i18n } = useTranslation();
  const theme = useTheme();
  const [searchParams, setSearchParams] = useSearchParams();
  const langParam = searchParams.get('language');
  const smallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const changeLang = (lang: any) => {
    // it's done so language swap is not blocked by the language change
    searchParams.set('langSwapped', lang);
    setSearchParams(searchParams, { replace: true });

    void i18n.changeLanguage(lang);
    document.body.dir = i18n.dir();
    theme.direction = i18n.dir();
  };

  const language = useLanguage();
  return (
    <Grid flexDirection={smallScreen ? 'column' : 'row'} className="order-page-footer" gap={smallScreen ? 1 : 2}>
      {!langParam && languages.length > 1 && (
        <Grid flexDirection="row" gap={1} className="language-container">
          <OrderPageTypography color="footer">{t('order_page.language')}</OrderPageTypography>
          <Grid onClick={() => changeLang('en-US')} className={i18n.language.includes('en') ? 'selected' : ''}>
            <OrderPageTypography color="footer">English</OrderPageTypography>
          </Grid>

          <OrderPageTypography color="footer">|</OrderPageTypography>
          <Grid onClick={() => changeLang('he')} className={i18n.language === 'he' ? 'selected' : ''}>
            <OrderPageTypography color="footer">עברית</OrderPageTypography>
          </Grid>
        </Grid>
      )}
      <Box className="powered-by">
        <OrderPageTypography color="footer">Powered by</OrderPageTypography>
        <a
          href={language === 'he' ? 'https://www.monkeybook.io/he' : 'https://www.monkeybook.io/'}
          target="_blank"
          rel="noreferrer"
          onClick={() => {
            posthog.capture('Powered By clicked', { accountId, url: location.pathname, language });
          }}>
          <img
            alt="powered by Webook"
            className="logo"
            src={
              theme.customTheme?.darkLogo ? '/images/logos/monkeybook-dark.png' : '/images/logos/monkeybook-light.png'
            }
          />
        </a>
      </Box>
    </Grid>
  );
};

export default MonkeybookWidgetFooter;
