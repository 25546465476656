import * as React from 'react';
import { useState } from 'react';
import { Grid, Grow, useMediaQuery, useTheme } from '@mui/material';
import moment from 'moment';
import { LoadingButton } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import * as yup from 'yup';

import { Formik } from 'formik';
import {
  AccountData,
  EntityType,
  OrderPageData,
  OrderPageTypes,
  RepeatingUnavailabilityRequestData,
  UnavailabilityData,
  UnavailabilityDetailData,
  UnavailabilityService,
} from '../../../../api';
import { useAuth } from '../../../../session/InternalAuthProvider';
import { UnavailabilityOrderPagesMultiSelect } from './UnavailabilityOrderPagesMultiSelect';
import { UseRepeatingDays } from '../../../components/UseRepeatingDays';
import { useUnavailabilities } from '../../../swr/useUnavailabilities';
import { getOrderPages } from '../../../../common/AccountUtils';
import { DialogState, RecurrentUpdateDialog } from '../../../components/RecurrentUpdateDialog';
import { FormTextField } from '../../../../experienceSettings/formComponents/FormTextField';
import { FormDatePicker } from '../../../../experienceSettings/formComponents/FormDatePicker';
import { FormSwitch } from '../../../../experienceSettings/formComponents/FormSwitch';
import { FormTimePicker } from '../../../../experienceSettings/formComponents/FormTimePicker';

export const MAX_PARALLEL_PERSON_CAPACITY = 'max_parallel_person_capacity';
export const CAPACITIES_BLOCKS_ARRAY = 'capacities_blocks_array';
export const STATIONS = 'stations';
const DATE_FORMAT = 'YYYY-MM-DD';
type UnavailabilityDialogProps = {
  setOpen: (open: boolean) => void;
  unavailability?: UnavailabilityData;
  calendarStartDate: string;
  calendarEndDate: string;
};
export type UnavailabilityFormValues = {
  all_day: boolean;
  description: string;
  start_date: string;
  end_date: string;
  start_time: number;
  end_time: number;
  unavailability_details?: Array<UnavailabilityDetailData>;
  repeating?: RepeatingUnavailabilityRequestData;
  recurrent_unavailability_id?: string;
  id?: string;
};
export const UnavailabilityDialog = ({
  setOpen,
  unavailability,
  calendarStartDate,
  calendarEndDate,
}: UnavailabilityDialogProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [dialogStatus, setDialogStatus] = useState(DialogState.CLOSED);
  const { t } = useTranslation();

  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const orderPages = getOrderPages(account);
  // blocking event order pages doesnt make sense as you should just lower the amount of participants.
  const privateOrdersPages = orderPages.filter((x: OrderPageData) => x.order_page_type !== OrderPageTypes.EVENT);
  const hasRecurrentUnavailability = !!unavailability?.recurrent_unavailability_id;
  const [loading, setLoading] = useState(false);

  const defaultStartMoment = moment().hours(9).minutes(0).seconds(0);
  const startInSec = defaultStartMoment.diff(moment().startOf('day'), 'seconds');
  const defaultEndMoment = moment().hours(17).minutes(0).seconds(0);
  const endInSec = defaultEndMoment.diff(moment().startOf('day'), 'seconds');
  const startDate = unavailability?.start_time ? moment.unix(unavailability?.start_time) : moment().startOf('day');
  const isUpdatingUnavailability = !!unavailability;
  const getInitialCustomDetails = (orderPageData: OrderPageData) => {
    const orderPage = privateOrdersPages.filter((o: any) => o.id === orderPageData.id)[0];
    const customDetails = {};
    for (const customDetail of orderPage.custom_details) {
      if (customDetail.cls === 'CustomResourceDetail') {
        // @ts-ignore
        customDetails[customDetail.name] = customDetail.max;
      }
    }
    if (orderPage.availability_settings?.restrictions_obj?.max_parallel_person_capacity?.parallel_persons_capacities) {
      // @ts-ignore
      customDetails[CAPACITIES_BLOCKS_ARRAY] =
        // eslint-disable-next-line max-len
        orderPage.availability_settings?.restrictions_obj?.max_parallel_person_capacity?.parallel_persons_capacities;
    }
    return customDetails;
  };

  const initialValues: UnavailabilityFormValues = isUpdatingUnavailability
    ? {
        description: unavailability.description,
        all_day: unavailability.all_day,
        start_date: unavailability.date,
        end_date: unavailability.date,
        start_time: unavailability.start_time,
        end_time: unavailability.end_time,
        unavailability_details: unavailability.unavailability_details,
        id: unavailability.id,
        recurrent_unavailability_id: unavailability.recurrent_unavailability_id,
      }
    : {
        description: '',
        all_day: true,
        start_date: moment().startOf('day').format(DATE_FORMAT),
        end_date: moment().startOf('day').format(DATE_FORMAT),
        start_time: startInSec,
        end_time: endInSec,
        unavailability_details: privateOrdersPages.map((x: OrderPageData) => ({
          order_page_id: x.id,
          custom_details: getInitialCustomDetails(x),
        })),
        repeating: {
          enabled: false,
          days: [0, 1, 2, 3, 4, 5, 6],
        },
      };
  const validationSchema = yup.object().shape({
    description: yup.string().required(t('calendar.unavailability_dialog.description_required')),
    all_day: yup.boolean(),
    start_date: yup.date().required(t('calendar.unavailability_dialog.date_required')),
    start_time: yup.date().when('allDay', {
      is: false,
      then: yup.date().required(t('calendar.unavailability_dialog.start_time_required')),
    }),
    end_time: yup.date().when('allDay', {
      is: false,
      then: yup.date().required(t('calendar.unavailability_dialog.end_time_required')),
    }),

    unavailability_details: yup.array().of(
      yup.object().shape({
        order_page_id: yup.string().required('Order page ID is required'),
        custom_details: yup.object(),
      }),
    ),
  });
  const {
    addItems: addUavailabilities,
    updateItem: updateUavailability,
    reFetch: refetchUnavliability,
  } = useUnavailabilities({
    startDate: calendarStartDate,
    endDate: calendarEndDate,
  });
  const { RepeatingDaysComponent } = UseRepeatingDays({
    minDate: startDate,
    initialDays: initialValues.repeating?.days,
    initialEndDate: initialValues.end_date,
  });

  const handleClose = () => {
    setOpen(false);
  };

  const updateUnavliability = async (values: UnavailabilityFormValues) => {
    const updated = await UnavailabilityService.editUnavailability({
      id: values.id!,
      date: values.start_date,
      ...values,
    });
    updateUavailability(updated);
    setLoading(false);
    setDialogStatus(DialogState.CLOSED);
    handleClose();
  };
  const updateRepeatingUnavliability = async (values: UnavailabilityFormValues) => {
    await UnavailabilityService.editUnavailabilityRecurrent({
      recurrent_unavailability_id: values.recurrent_unavailability_id!,
      date: values.start_date,
      ...values,
    });
    void refetchUnavliability();
    setLoading(false);
    setDialogStatus(DialogState.CLOSED);
    handleClose();
  };
  const createUnavliability = async (values: UnavailabilityFormValues) => {
    if (isUpdatingUnavailability && hasRecurrentUnavailability) {
      setDialogStatus(DialogState.UPDATE);
      return;
    }
    setLoading(true);
    if (isUpdatingUnavailability) {
      await updateUnavliability(values);
    } else {
      const res = await UnavailabilityService.createUnavailability({
        ...values,
      });
      addUavailabilities(res);
      setLoading(false);
      handleClose();
    }
  };
  const onSubmit = async (values: UnavailabilityFormValues) => {
    setLoading(true);
    try {
      if (isUpdatingUnavailability) {
        if (hasRecurrentUnavailability) {
          setDialogStatus(DialogState.UPDATE);
        } else {
          await updateUnavliability(values);
        }
      } else {
        await createUnavliability(values);
      }
    } catch (error) {
      console.error('Error submitting form:', error);
    } finally {
      setLoading(false);
    }
  };
  return (
    <Grid style={{ height: '100%', overflow: 'auto' }}>
      <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
        {({ values, handleSubmit, setFieldValue, touched, isSubmitting, submitForm }) => {
          return (
            <form onSubmit={handleSubmit}>
              <Grid
                container
                flexDirection="column"
                width={isSmallScreen ? window.innerWidth : '550px'}
                px={isSmallScreen ? '8px' : '16px'}>
                <Grid p="8px" container flexDirection="column" gap={2}>
                  <Grid item>
                    <FormTextField
                      width={231}
                      label={t('calendar.unavailability_dialog.description')}
                      fieldName={'description'}
                      isFieldUpdate={isUpdatingUnavailability}
                      disabled={isSubmitting}
                    />
                  </Grid>

                  <Grid>
                    <FormDatePicker
                      disabled={isSubmitting}
                      fieldName={'start_date'}
                      isFieldUpdate={isUpdatingUnavailability}
                      label={t('calendar.unavailability_dialog.date')}
                      onChange={(newValue) => {
                        if (newValue?.isSameOrAfter(values.end_date)) {
                          void setFieldValue('end_date', newValue?.format(DATE_FORMAT));
                        }
                      }}
                    />
                  </Grid>
                  <Grid
                    item
                    borderRadius="8px"
                    border="1px solid #c4c4c4"
                    p={1}
                    pb={values.all_day ? 1 : 2}
                    maxWidth={values.all_day ? '231px' : undefined}>
                    <Grid item>
                      <FormSwitch
                        fieldName={'all_day'}
                        label={t('calendar.unavailability_dialog.all_day')}
                        isFieldUpdate={isUpdatingUnavailability}
                        disabled={isSubmitting}
                      />
                    </Grid>

                    {!values.all_day && (
                      <Grow in={!values.all_day} timeout={1200}>
                        <Grid container gap={2}>
                          <Grid item>
                            <FormTimePicker
                              minTime={moment().set({ hour: 7, minute: 0, second: 0, millisecond: 0 })}
                              label={t('calendar.unavailability_dialog.start_time')}
                              fieldName={'start_time'}
                              disabled={isSubmitting}
                              isFieldUpdate
                            />
                          </Grid>
                          <Grid item>
                            <FormTimePicker
                              minTime={moment().set({ hour: 7, minute: 0, second: 0, millisecond: 0 })}
                              label={t('calendar.unavailability_dialog.end_time')}
                              fieldName={'end_time'}
                              disabled={isSubmitting}
                              isFieldUpdate
                            />
                          </Grid>
                        </Grid>
                      </Grow>
                    )}
                  </Grid>
                  {!isUpdatingUnavailability && (
                    <Grid
                      item
                      borderRadius="8px"
                      border="1px solid #c4c4c4"
                      p={1}
                      maxWidth={!values.repeating?.enabled ? '231px' : undefined}>
                      <FormSwitch
                        fieldName={'repeating.enabled'}
                        label={t('calendar.unavailability_dialog.repeating')}
                        isFieldUpdate={isUpdatingUnavailability}
                        onChange={(e: any) => {
                          void setFieldValue('repeating.enabled', e.target.checked);
                          void setFieldValue('end_date', moment(values.start_date).startOf('day').format(DATE_FORMAT));
                        }}
                        disabled={isSubmitting}
                      />

                      {values.repeating?.enabled && (
                        <Grow in={values.repeating?.enabled} timeout={500}>
                          <Grid>
                            <RepeatingDaysComponent
                              setFormikDays={(newDays) => {
                                void setFieldValue('repeating.days', newDays);
                                void setFieldValue('repeating.enabled', true);
                              }}
                              setFormikEndDate={(newEndDate) => setFieldValue('end_date', newEndDate)}
                            />
                          </Grid>
                        </Grow>
                      )}
                    </Grid>
                  )}
                  {privateOrdersPages && (
                    <Grid container flexDirection="column" mt={4} gap={2} maxWidth={'400px'}>
                      <Grid item mb={-2}>
                        <Typography variant="h5">{t('calendar.unavailability_dialog.pagestitle')}</Typography>
                      </Grid>

                      <UnavailabilityOrderPagesMultiSelect
                        orderPages={privateOrdersPages}
                        unavailabilityDetails={values.unavailability_details || []}
                        setUnavailabilityDetails={(selected: UnavailabilityDetailData[]) =>
                          setFieldValue('unavailability_details', selected)
                        }
                        isFieldUpdate={isUpdatingUnavailability}
                      />
                    </Grid>
                  )}
                </Grid>

                <Grid p={2} container justifyContent={'flex-end'}>
                  <LoadingButton
                    onClick={submitForm}
                    loading={loading}
                    variant="contained"
                    sx={{ textTransform: 'none' }}
                    disabled={!touched}>
                    {t('save')}
                  </LoadingButton>
                </Grid>
                {dialogStatus !== DialogState.CLOSED && (
                  <RecurrentUpdateDialog
                    dialogState={dialogStatus}
                    setDialogStatus={setDialogStatus}
                    recurrentId={unavailability?.recurrent_unavailability_id}
                    recurrentEntityType={EntityType.UNAVAILABILITY}
                    updateSingle={() => updateUnavliability(values)}
                    updateRecurrent={() => updateRepeatingUnavliability(values)}
                    deleteSingle={() =>
                      new Promise(() => {
                        console.log('Only update here');
                      })
                    }
                    deleteReccurrent={() =>
                      new Promise(() => {
                        console.log('Only update here');
                      })
                    }
                    texts={{
                      update: {
                        cta: t('calendar.unavailability_dialog.edit_cta'),
                        title: t('calendar.unavailability_dialog.edit_title_questions'),
                        singleOption: t('calendar.unavailability_dialog.single'),
                        recurrentOption: t('calendar.unavailability_dialog.repeatingUpdate'),
                        recurrentCount: t('calendar.delete_unavailability.recurrent_count'),
                      },
                      delete: {
                        title: 'Delete is not supported yet for this dialog',
                        singleOption: 'Delete is not supported yet for this dialog',
                        recurrentOption: 'Delete is not supported yet for this dialog',
                        recurrentCount: 'Delete is not supported yet for this dialog',
                      },
                    }}
                  />
                )}
              </Grid>
            </form>
          );
        }}
      </Formik>
    </Grid>
  );
};
