/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * An enumeration.
 */
export enum PrivateSlotType {
  FIXED = 'fixed',
  INTERVAL = 'interval',
}
