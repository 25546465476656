import { DateAvailabilityOut, OrderDetailsRequestData, SlimEventData, Tip, VoucherOut } from '../../api';
import { CustomTheme, SpecificEventError, VoucherError } from '../OrderUtils';
import { OrderPageIntent } from '../OrderPageIntent';

export type SpecificOrderPageConfig = {
  onlyCouples?: boolean;
  onlyVouchers?: boolean;
  noMinDate?: boolean;
  troubleshoot?: boolean;
  preSelectedDate?: string;
};

export type OrderPageState = {
  isInitialized: boolean;
  activeStep: number;
  account: any;
  experience: any;
  orderPage: any;
  paymentUrl: any;
  details: OrderDetailsRequestData;
  dateAndTimeDetails: {
    date: any;
    time?: any;
  };
  personalDetails: {
    firstname: string;
    lastname: string;
    email: string;
    phone: string;
  };
  order_id: string;
  voucher?: VoucherOut;
  voucherError?: VoucherError;
  orderPageIntent: OrderPageIntent;
  theme?: CustomTheme;
  has_payment_integration: boolean;
  isLoadingDates: boolean;
  availableDates: DateAvailabilityOut[];
  canSpam?: boolean;
  specificOrderPage?: SpecificOrderPageConfig;
  returningCustomer: boolean;
  specificEvent?: SlimEventData;
  specificEventError?: SpecificEventError;
  tip?: Tip;
  discountId?: string;
  returningCustomerFromOrderId?: string;
};

export const orderPageInitialState: OrderPageState = {
  isInitialized: false,
  activeStep: 0,
  account: null,
  experience: null,
  orderPage: null,
  paymentUrl: null,
  details: {
    persons: 0,
    duration: 0,
    price: 0,
    rooms: 1,
    custom_details: {},
  },
  dateAndTimeDetails: {
    date: null,
    time: null,
  },
  personalDetails: {
    firstname: '',
    lastname: '',
    email: '',
    phone: '',
  },
  order_id: '',
  voucher: undefined,
  voucherError: undefined,
  orderPageIntent: OrderPageIntent.BUY_NOW,
  theme: undefined,
  has_payment_integration: false,
  isLoadingDates: true,
  availableDates: [],
  canSpam: true,
  specificOrderPage: {
    onlyCouples: false,
    onlyVouchers: false,
    noMinDate: false,
    troubleshoot: false,
  },
  returningCustomer: false,
  tip: undefined,
  discountId: undefined,
  returningCustomerFromOrderId: undefined,
};
