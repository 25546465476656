import { FormControl, FormControlLabel, Grid, Radio, RadioGroup } from '@mui/material';
import * as React from 'react';
import { useEffect, useState } from 'react';
import Typography from '@mui/material/Typography';
import ConfirmationDialog from '../../common/ui/ConfimationDialog';
import { CalendarService, EntityType } from '../../api';

export enum DialogMode {
  SINGLE = 'single',
  FROM_NOW_ON = 'from_now_on',
}

export enum DialogState {
  UPDATE = 'update',
  DELETE = 'delete',
  CLOSED = 'closed',
}

type TextCtas = {
  cta?: string;
  title: string;
  singleOption: string;
  recurrentOption: string;
  recurrentCount: string;
};
type TextsProps = {
  update: TextCtas;
  delete: TextCtas;
};
type RecurrentUpdateDialogConfirmationProps = {
  dialogState: DialogState;
  setDialogStatus: (dialogStatus: DialogState) => void;
  deleteSingle: () => Promise<void>;
  deleteReccurrent: () => Promise<void>;
  updateSingle: () => Promise<void>;
  updateRecurrent: () => Promise<void>;
  recurrentId?: string;
  recurrentEntityType: EntityType;
  texts: TextsProps;
};
export const RecurrentUpdateDialog = ({
  dialogState,
  recurrentId,
  deleteSingle,
  deleteReccurrent,
  updateSingle,
  updateRecurrent,
  setDialogStatus,
  recurrentEntityType,
  texts,
}: RecurrentUpdateDialogConfirmationProps) => {
  const [dialogMode, setDialogMode] = useState<DialogMode>(DialogMode.SINGLE);
  const [recurrentCount, setRecurrentCount] = useState<number | null>(null);
  const getRecurrentCount = async () => {
    try {
      if (!recurrentId) {
        return;
      }
      const count = await CalendarService.getRecurrentCount(recurrentId, recurrentEntityType);
      setRecurrentCount(count);
    } catch (error) {
      console.error('Failed to fetch recurrent unavailability count', error);
    }
  };

  useEffect(() => {
    setDialogMode(DialogMode.SINGLE);
    void getRecurrentCount();
  }, [recurrentId]);
  const resolvedTexts = dialogState !== DialogState.CLOSED ? texts[dialogState] : texts[DialogState.UPDATE];

  const onClose = async (actionConfirmed: boolean) => {
    if (!actionConfirmed) {
      setDialogStatus(DialogState.CLOSED);
      return;
    }
    if (dialogMode === DialogMode.SINGLE) {
      if (dialogState === DialogState.DELETE) {
        await deleteSingle();
      } else if (dialogState === DialogState.UPDATE) {
        await updateSingle();
      }
      return;
    }

    // dialogMode === DialogMode.FROM_NOW_ON means recurrent
    if (dialogState === DialogState.DELETE) {
      await deleteReccurrent();
    } else if (dialogState === DialogState.UPDATE) {
      await updateRecurrent();
    }

    setDialogStatus(DialogState.CLOSED);
  };

  return (
    <ConfirmationDialog
      confirmButtonColor={dialogState === DialogState.UPDATE ? 'primary' : undefined}
      ctaText={resolvedTexts.cta}
      title={resolvedTexts.title}
      content={
        <Grid container flexDirection="column">
          <Grid item>
            <FormControl component="fieldset">
              <RadioGroup value={dialogMode} onChange={(e) => setDialogMode(e.target.value as DialogMode)}>
                <FormControlLabel value={DialogMode.SINGLE} control={<Radio />} label={resolvedTexts.singleOption} />
                <FormControlLabel
                  value={DialogMode.FROM_NOW_ON}
                  control={<Radio />}
                  label={
                    <Grid container gap={1} alignItems="baseline">
                      {resolvedTexts.recurrentOption}
                      {recurrentCount !== null && (
                        <Typography variant="caption" color="grey">
                          {resolvedTexts.recurrentCount.replace('{{count}}', recurrentCount.toString())}
                        </Typography>
                      )}
                    </Grid>
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
        </Grid>
      }
      isOpen={dialogState !== DialogState.CLOSED}
      onClose={onClose}
    />
  );
};
