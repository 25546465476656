import './ToggleButtonGroupUiObject.scss';
import React, { useEffect, useState } from 'react';
import { ToggleButtonGroup } from '@mui/material';
import { useLanguage } from '../../common/GeneralUtils';
import { OrderPageToggleButton } from '../themedComponents/OrderPageToggleButton';
import { OrderPageState } from '../orderPageContext/OrderPageState';

type ToggleButtonGroupUiObjectProps = {
  uiObject: any;
  orderState: OrderPageState;
  setOrderDetailValue: (name: string, value: string) => void;
  setCustomOrderDetailValue: (name: string, value: string) => void;
  setOrderCustomDetails: (details: any) => void;
};
const ToggleButtonGroupUiObject = ({
  uiObject,
  orderState,
  setOrderDetailValue,
  setCustomOrderDetailValue,
  setOrderCustomDetails,
}: ToggleButtonGroupUiObjectProps) => {
  const language = useLanguage();

  const defaultValue =
    uiObject.data_type === 'custom'
      ? uiObject.options[0].value
      : `${orderState.details[uiObject.data_type as keyof typeof orderState.details]}`;
  // ^ todo remove when there are more types

  useEffect(() => {
    if (uiObject.data_type === 'custom') {
      setCustomOrderDetailValue(uiObject.name, defaultValue);
    }
  }, []);

  const [value, setValue] = useState(defaultValue);

  const onValueChange = (e: any, val: any) => {
    if (val != null) {
      // for deselection
      setValue(val);
      if (uiObject.data_type === 'custom') {
        const data = {
          [uiObject.name]: val,
        };

        setOrderCustomDetails(data);
      } else {
        setOrderDetailValue(uiObject.data_type, val);
      }
    }
  };

  return (
    <div className="toggle-button-ui-object">
      <div className="text-label" dangerouslySetInnerHTML={{ __html: uiObject.label[language] }} />

      <div className="toggle-button-container">
        <ToggleButtonGroup value={value} exclusive onChange={onValueChange}>
          {uiObject.options.map((option: any) => {
            return (
              <OrderPageToggleButton key={option.value} className="toggle-button" value={`${option.value}`}>
                {option.label[language]}
              </OrderPageToggleButton>
            );
          })}
        </ToggleButtonGroup>
      </div>
    </div>
  );
};

export default ToggleButtonGroupUiObject;
