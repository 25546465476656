import { Field, FieldProps } from 'formik';
import React from 'react';
import { Grid, TextField, useMediaQuery } from '@mui/material';
import Typography from '@mui/material/Typography';
import { StyledErrorMessage } from '../StyledErrorMessage';
import { theme } from '../../common/GeneralUtils';
import { FormElementProps, getShouldMarkFieldAsUpdated } from './utils';

type FormTextFieldProps = FormElementProps & {
  width?: number;
  disabled?: boolean;
  caption?: string;
  endAdornment?: any;
  type?: React.InputHTMLAttributes<unknown>['type'];
  minRows?: number;
};
export const FormTextField = ({
  disabled,
  label,
  fieldName,
  caption,
  width,
  endAdornment,
  type = 'text',
  minRows = 1,
  isFieldUpdate = true,
}: FormTextFieldProps) => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));
  const shouldRenderBorder = isSmallScreen && caption;
  return (
    <Grid
      container
      gap={caption ? 2 : 0}
      border={shouldRenderBorder ? '1px solid #bfbfbf' : undefined}
      borderRadius={shouldRenderBorder ? '4px' : undefined}
      p={shouldRenderBorder ? 1 : undefined}
      flexDirection={shouldRenderBorder ? 'column' : 'row'}
      justifyContent="flex-start">
      <Grid item md={width ? undefined : 4} xs={width ? undefined : 12} width={width}>
        <Field name={fieldName}>
          {({ field, form }: FieldProps<string | number>) => {
            const shouldMarkFieldAsUpdated = getShouldMarkFieldAsUpdated(form, fieldName, field, isFieldUpdate);
            return (
              <TextField
                minRows={minRows}
                size={'small'}
                {...field}
                type={type}
                label={label}
                variant="outlined"
                fullWidth
                multiline
                disabled={disabled}
                InputProps={{ endAdornment }}
                onChange={(e) => {
                  // so it doesnt set a string from a number input
                  const { value } = e.target;
                  void form.setFieldValue(field.name, type === 'number' ? parseFloat(value) || 0 : value);
                }}
                sx={{
                  '& .MuiOutlinedInput-root': {
                    '& fieldset': {
                      borderColor: shouldMarkFieldAsUpdated ? '#47b406' : undefined,
                      borderWidth: shouldMarkFieldAsUpdated ? '4px' : undefined,
                    },
                    '&:hover fieldset': {
                      borderColor: shouldMarkFieldAsUpdated ? '#47b406' : undefined,
                    },
                    '&.Mui-focused fieldset': {
                      borderColor: shouldMarkFieldAsUpdated ? '#47b406' : undefined,
                    },
                  },
                }}
              />
            );
          }}
        </Field>
        <StyledErrorMessage name={fieldName} />
      </Grid>
      <Grid item>
        <Typography fontSize="12px" color="grey">
          {caption}
        </Typography>
      </Grid>
    </Grid>
  );
};
