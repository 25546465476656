import { useTranslation } from 'react-i18next';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { FormTextField } from '../../formComponents/FormTextField';
import { Language } from '../../../api';

type InformationSectionProps = {
  language: Language;
  disabled: boolean;
};
export const InformationSectionLanguageTab = ({ language, disabled }: InformationSectionProps) => {
  const { t } = useTranslation();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const textBoxWidth = isSmallScreen ? 300 : 500;
  return (
    <Grid container flexDirection="column" gap={2}>
      <FormTextField
        isFieldUpdate
        width={textBoxWidth}
        label={t('editOrderPage.orderPageName')}
        fieldName={`label.${language}`}
        disabled={disabled}
        caption={t('editOrderPage.orderPageNameCaption')}
      />
      <FormTextField
        isFieldUpdate
        width={textBoxWidth}
        label={t('editOrderPage.orderPageDescription')}
        fieldName={`description.${language}`}
        disabled={disabled}
        caption={t('editOrderPage.orderPageDescriptionCaption')}
      />

      <FormTextField
        isFieldUpdate
        width={textBoxWidth}
        label={t('editOrderPage.activityLabel')}
        fieldName={`activity_label.${language}`}
        disabled={disabled}
        caption={t('editOrderPage.activityLabelCaption')}
      />

      <FormTextField
        isFieldUpdate
        width={textBoxWidth}
        label={t('editOrderPage.orderPagePhone')}
        fieldName={`phone.${language}`}
        disabled={disabled}
        caption={t('editOrderPage.orderPagePhoneCaption')}
      />
    </Grid>
  );
};
