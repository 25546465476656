import moment from 'moment';
import { AccordionDetails, FormControl, Grid, MenuItem, Select, ThemeProvider, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';
import { DatePicker, MobileTimePicker, PickersDay } from '@mui/x-date-pickers';
import { isEmpty } from 'lodash';
import { CacheProvider } from '@emotion/react';
import merge from 'lodash/merge';
import FinalDateDetails from '../../FinalDateDetails';
import { cacheLtr, cacheRtl, ltrTheme, rtlTheme } from '../../../../../common/GeneralUtils';
import { OccupiedPickersDay } from '../../../../../order_page/steps/dateAndTimeStep/DateAndTimeStep';
import { Accordion, AccordionPanelsNames, AccordionSummary } from '../Accordion';
import { AccountData, HolidayCalendar, TimeSlotResponseOut } from '../../../../../api';
import { useAuth } from '../../../../../session/InternalAuthProvider';
import { getDateFormat, getTimeFormat } from '../../../../../common/getDateFormat';

type DateAndTimePanelProps = {
  dateValue: moment.Moment;
  isEventOrderPage: boolean;
  shouldDisableDate: (date: moment.Moment) => boolean;
  onDatePickerChange: (date: moment.Moment | null) => void;
  availableTimesForEvent: TimeSlotResponseOut[]; // You may need to define this type separately
  timeSeconds: number;
  setTimeSeconds: (value: number) => void;
  privateOrderEndTimeSeconds: number;
  setPrivateOrderEndTimeSeconds: (value: number) => void;
  duration: number;
  expanded?: AccordionPanelsNames;
  handleChange: any;
};
export const DateAndTimePanel = ({
  dateValue,
  isEventOrderPage,
  shouldDisableDate,
  onDatePickerChange,
  availableTimesForEvent,
  timeSeconds,
  setTimeSeconds,
  privateOrderEndTimeSeconds,
  setPrivateOrderEndTimeSeconds,
  duration,
  expanded,
  handleChange,
}: DateAndTimePanelProps) => {
  const theme = useTheme();
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const timeFormat = getTimeFormat(account);
  const { longDateFormat } = getDateFormat(account);
  const { t } = useTranslation();
  const { i18n } = useTranslation();
  const dir = i18n.dir();
  const [dateOpen, setDateOpen] = useState(false);
  const [startTimeIsOpen, setStartTimeOpen] = useState(false);
  const [endTimeIsOpen, setEndTimeIsOpen] = useState(false);
  const renderWeekPickerDay = (props: any) => {
    if (props.disabled) {
      return <OccupiedPickersDay {...props} />;
    }
    return <PickersDay {...props} />;
  };
  return (
    <Accordion onChange={handleChange} expandedPanelName={expanded} panelName={AccordionPanelsNames.DateAndTimePanel}>
      <AccordionSummary
        title={t('calendar.edit_order.dateandtime')}
        summaryComponent={<FinalDateDetails date={dateValue} timeSeconds={timeSeconds} duration={duration} />}
      />
      <AccordionDetails>
        <Grid container item gap={2} flexWrap="nowrap">
          <DatePicker
            open={dateOpen}
            onOpen={() => setDateOpen(true)}
            onClose={() => setDateOpen(false)}
            label={t('calendar.edit_order.date')}
            slots={{
              // @ts-ignore
              day: renderWeekPickerDay,
            }}
            format={longDateFormat}
            value={dateValue}
            shouldDisableDate={shouldDisableDate}
            onChange={onDatePickerChange}
          />
          {isEventOrderPage ? (
            <Grid item>
              {!isEmpty(availableTimesForEvent) ? (
                <FormControl>
                  <Select
                    value={timeSeconds}
                    renderValue={() => moment.unix(timeSeconds).tz('utc').format(timeFormat)}
                    onChange={(event) => {
                      setTimeSeconds(+event.target.value);
                    }}>
                    {availableTimesForEvent.map((x) => (
                      <MenuItem value={x.seconds} key={`menuitem-${x.seconds}`}>
                        {moment.unix(x.seconds).tz('utc').format(timeFormat)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              ) : (
                <Grid />
              )}
            </Grid>
          ) : (
            <CacheProvider value={dir === 'rtl' ? cacheRtl : cacheLtr}>
              <ThemeProvider theme={merge(theme, dir === 'rtl' ? rtlTheme : ltrTheme)}>
                <Grid container item gap={2} flexWrap="nowrap">
                  <Grid item maxWidth={100}>
                    <MobileTimePicker
                      minTime={moment().set({ hour: 6, minute: 0, second: 0, millisecond: 0 })}
                      ampm={account.holiday_calendar === HolidayCalendar.US}
                      label={t('calendar.edit_order.start_time')}
                      open={startTimeIsOpen}
                      onOpen={() => setStartTimeOpen(true)}
                      onClose={() => setStartTimeOpen(false)}
                      value={moment.unix(timeSeconds).tz('utc')}
                      minutesStep={15}
                      onChange={(newValue) => {
                        const val = newValue?.unix() || timeSeconds;
                        setTimeSeconds(val);
                        if (!isEventOrderPage) {
                          setPrivateOrderEndTimeSeconds(val + duration);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item maxWidth={100}>
                    <MobileTimePicker
                      minTime={moment().set({ hour: 6, minute: 0, second: 0, millisecond: 0 })}
                      ampm={account.holiday_calendar === HolidayCalendar.US}
                      label={t('calendar.edit_order.end_time')}
                      open={endTimeIsOpen}
                      onOpen={() => setEndTimeIsOpen(true)}
                      onClose={() => setEndTimeIsOpen(false)}
                      value={moment.unix(privateOrderEndTimeSeconds).tz('utc')}
                      minutesStep={15}
                      onChange={(newValue) => {
                        setPrivateOrderEndTimeSeconds(newValue?.unix() || timeSeconds);
                      }}
                    />
                  </Grid>
                </Grid>
              </ThemeProvider>
            </CacheProvider>
          )}
        </Grid>
      </AccordionDetails>
    </Accordion>
  );
};
