import './instrumentSentry';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.scss';
import { PostHogProvider } from 'posthog-js/react';
import { BrowserRouter } from 'react-router-dom';
import { CapturedNetworkRequest } from 'posthog-js';
import App from './App';
import ErrorBoundary from './ErrorBoundery';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <ErrorBoundary>
      <BrowserRouter>
        <PostHogProvider
          apiKey={import.meta.env.VITE_REACT_APP_PUBLIC_POSTHOG_KEY}
          options={{
            disable_session_recording: true,
            session_recording: { maskCapturedNetworkRequestFn: (request: CapturedNetworkRequest) => request },
          }}>
          <App />
        </PostHogProvider>
      </BrowserRouter>
    </ErrorBoundary>
  </React.StrictMode>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals();
