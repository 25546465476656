import { Formik } from 'formik';
import { Alert, Checkbox, DialogContent, FormControlLabel, Grid, Snackbar, TextField } from '@mui/material';
import React, { useState } from 'react';
import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import { LoadingButton } from '@mui/lab';
import DialogActions from '@mui/material/DialogActions';
import { PaymentLinkOut, PaymentLinkService } from '../../../../../api';
import { ColorButton } from '../../../../../common/ui/ConfimationDialog';
import { getLinkToPaymentLink } from '../../OrderDetails';

type AddPaymentItemDialogProps = {
  setLoadingPaymentLinks: (isLoadingPaymentLinks: boolean) => void;
  accountId: string;
  orderId: string;
  setPaymentLinks: (paymentLinks: PaymentLinkOut[]) => void;
  setEditOrderState: (editOrderState: any) => void;
  editOrderState: any;
  setIsDialogOpen: (isDialogOpen: boolean) => void;
  isDialogOpen: boolean;
};
export const AddPaymentItemDialog = ({
  setLoadingPaymentLinks,
  accountId,
  orderId,
  setPaymentLinks,
  setEditOrderState,
  editOrderState,
  setIsDialogOpen,
  isDialogOpen,
}: AddPaymentItemDialogProps) => {
  const { t } = useTranslation();
  const [copySnackbarOpen, setCopySnackbarOpen] = useState(false);
  const [isAddingItem, setIsAddingItem] = useState(false);
  const extraPaymentsLineInitialValue = {
    price: 0,
    title: '',
    shouldCreatePaymentLink: false,
  };

  const extraPaymentsLineValidationSchema = yup.object().shape({
    title: yup.string().required(t('calendar.edit_order.mandatoryField')),
    price: yup.number().required(t('calendar.edit_order.mandatoryField')),
  });
  // @ts-ignore
  const onSubmit = async (values, { resetForm }) => {
    setIsAddingItem(true);
    if (!values.shouldCreatePaymentLink) {
      setEditOrderState({
        ...editOrderState,
        extraPaymentsLines: [...editOrderState.extraPaymentsLines, { title: values.title, price: values.price }],
      });
      resetForm();
      setIsDialogOpen(false);
      setIsAddingItem(false);
      return;
    }

    try {
      setLoadingPaymentLinks(true);
      const newLink = await PaymentLinkService.createPaymentLinkAdmin({
        ...values,
        account_id: accountId,
        order_id: orderId,
      });

      const newPaymentLinks = await PaymentLinkService.getPaymentLinkByOrderId(orderId);
      setPaymentLinks(newPaymentLinks.filter((x) => !x.archived));
      setIsDialogOpen(false);

      void navigator.clipboard.writeText(getLinkToPaymentLink(newLink.id));
      setCopySnackbarOpen(true);

      setLoadingPaymentLinks(false);
      resetForm();
    } catch (error) {
      console.error(error);
    }
    setIsAddingItem(false);
  };
  return (
    <Grid container flexDirection="column" gap={2}>
      <Formik
        enableReinitialize
        initialValues={extraPaymentsLineInitialValue}
        validationSchema={extraPaymentsLineValidationSchema}
        onSubmit={onSubmit}>
        {({ values, errors, touched, handleChange, submitForm }) => {
          return (
            <form>
              <Dialog open={isDialogOpen} onClose={() => setIsDialogOpen(false)}>
                <DialogTitle id="alert-dialog-title">
                  {t('calendar.edit_order.order_details.extraPaymentItem')}
                </DialogTitle>
                <DialogContent>
                  <Grid container flexDirection="column" flexWrap="nowrap" gap={2} m={2}>
                    <Grid item flexWrap="nowrap">
                      <TextField
                        size="small"
                        id="title"
                        name="title"
                        variant="outlined"
                        label={t('calendar.edit_order.description')}
                        value={values.title}
                        onChange={handleChange}
                        error={touched.title && Boolean(errors.title)}
                        helperText={touched.title && errors.title}
                      />
                    </Grid>
                    <Grid item>
                      <TextField
                        size="small"
                        variant="outlined"
                        type="number"
                        id="price"
                        name="price"
                        label={t('calendar.edit_order.priceWord')}
                        value={values.price}
                        onChange={handleChange}
                        error={touched.price && Boolean(errors.price)}
                        helperText={touched.price && errors.price}
                      />
                    </Grid>
                    <Grid>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="shouldCreatePaymentLink"
                            checked={values.shouldCreatePaymentLink}
                            onChange={handleChange}
                          />
                        }
                        label={<Typography fontSize={12}>{t('calendar.edit_order.paymentLink')}</Typography>}
                      />
                    </Grid>
                  </Grid>
                </DialogContent>
                <DialogActions>
                  <ColorButton onClick={() => setIsDialogOpen(false)}>{t('cancel')}</ColorButton>
                  <LoadingButton
                    loading={isAddingItem}
                    variant="contained"
                    onClick={submitForm}
                    sx={{ boxShadow: 'none' }}
                    autoFocus>
                    {t('add')}
                  </LoadingButton>
                </DialogActions>
              </Dialog>
            </form>
          );
        }}
      </Formik>
      <Snackbar
        open={copySnackbarOpen}
        onClose={() => setCopySnackbarOpen(false)}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setCopySnackbarOpen(false)} severity="success">
          {t('copiedToClipboard')}
        </Alert>
      </Snackbar>
    </Grid>
  );
};
