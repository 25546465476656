import React, { useMemo } from 'react';
import { Grid, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { priceFormatter } from './utils';
import { getHoliday } from '../common/getHoliday';
import { OrderPageTypography } from './themedComponents/OrderPageTypography';
import TimeUtils from '../common/TimeUtils';
import { useLanguage } from '../common/GeneralUtils';
import { OrderPageIntent } from './OrderPageIntent';

type PriceSummaryProps = {
  activeStep: number;
  orderPageIntent: OrderPageIntent;
  orderPageState: any;
  persons: number;
  date: string;
};
export const PriceSummary = ({ activeStep, orderPageIntent, orderPageState, persons, date }: PriceSummaryProps) => {
  const { t } = useTranslation();
  const language = useLanguage();
  const theme = useTheme();

  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const shouldShowDuration = orderPageState.orderPage.show_duration;
  const { price } = orderPageState.details;
  const formattedDuration = useMemo(
    () => TimeUtils.formatDuration(orderPageState.details.duration, t),
    [orderPageState.details.duration, t],
  );
  const shouldShowPriceSummary = activeStep === 0 && orderPageIntent !== OrderPageIntent.REDEEM_GIFT_CARD;
  return shouldShowPriceSummary ? (
    <Grid container display="flex" flexDirection="column" xs={isSmallScreen ? undefined : 6}>
      {shouldShowDuration && (
        <Grid item gap={1} sx={{ display: { xs: 'none', sm: 'flex' } }} flexDirection="row">
          <OrderPageTypography>{t('order_page.duration')}</OrderPageTypography>
          <OrderPageTypography bold>{formattedDuration}</OrderPageTypography>
        </Grid>
      )}
      <Grid item gap={1} display="flex" flexDirection="row">
        <OrderPageTypography>{t('order_page.perTicket')}</OrderPageTypography>
        <OrderPageTypography bold>
          {priceFormatter(+(price / persons).toFixed(0), orderPageState.account)}
        </OrderPageTypography>
      </Grid>
      {getHoliday(orderPageState.account.holiday_calendar, date) && (
        <Grid item gap={1} display="flex" flexDirection="row">
          <OrderPageTypography bold>
            {getHoliday(orderPageState.account.holiday_calendar, date, language)}
          </OrderPageTypography>
        </Grid>
      )}
      <Grid item gap={1} display="flex" flexDirection="row">
        <OrderPageTypography>{t('order_page.total')}</OrderPageTypography>
        <OrderPageTypography bold>{priceFormatter(price, orderPageState.account, true)}</OrderPageTypography>
      </Grid>
    </Grid>
  ) : null;
};
