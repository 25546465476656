import { Box, Grid } from '@mui/material';
import './EventBox.scss';
import React, { useEffect, useState } from 'react';
import { isEmpty } from 'lodash';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import { useFeatureFlagEnabled } from 'posthog-js/react';
import { useDraggable } from '@dnd-kit/core';
import { useAuth } from '../../session/InternalAuthProvider';
import { getOrderPage, getOrderPages } from '../../common/AccountUtils';
import LinearProgressWithLabel from '../../common/ui/LinearProgressWithLabel';
import { useCalendar } from '../CalendarContext';
import { useLanguage } from '../../common/GeneralUtils';
import { AccountData, EnrichedEventData } from '../../api';
import { getEmployeeNames } from '../CalendarUtils';
import { getTimeFormat } from '../../common/getDateFormat';

type EventBoxProps = {
  event: EnrichedEventData;
  css?: any;
  viewType: 'month' | 'day' | 'condensed';
  openEvent: (event: EnrichedEventData, e: any) => void;
  shouldNotDragNDrop?: boolean;
};
const EventBox = ({ event, css, viewType, openEvent, shouldNotDragNDrop }: EventBoxProps) => {
  // @ts-ignore
  const { authState } = useAuth();
  const [highlight, setHighlight] = useState(false);
  const timeFormat = getTimeFormat(authState?.account || undefined);

  const account = authState.account as unknown as AccountData;

  const { attributes, listeners, setNodeRef, transform, isDragging } = useDraggable({
    id: event.id,
  });
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const queryOrderId = urlParams.get('entityid');
    if (queryOrderId && queryOrderId === event.id) {
      setHighlight(true);
      setTimeout(() => {
        setHighlight(false);
        urlParams.delete('entityid');
        window.history.replaceState(null, '', `${window.location.pathname}?${urlParams.toString()}`);
      }, 3000); // Duration of the gradient animation
    }
  }, [event.id]);
  const language = useLanguage();
  const employeesFeatureEnabled = useFeatureFlagEnabled('employees-feature-enabled');

  const orderPage = getOrderPage(authState.account, event.order_page_id);
  const shouldShowRedBorder = event.employee_ids.length === 0 && employeesFeatureEnabled;
  const shouldShowOrangeBorder = event.hidden_from_customers;
  const border = shouldShowOrangeBorder
    ? '2px solid orange'
    : shouldShowRedBorder
    ? '2px solid red'
    : '1px solid rgb(230, 230, 230)';
  // @ts-ignore
  const { users, setShouldBlockSwiping } = useCalendar();
  useEffect(() => {
    setShouldBlockSwiping(isDragging);
  }, [isDragging, setShouldBlockSwiping]);
  const hasOnlyOneOrderPage = getOrderPages(account).length === 1;
  const customUsedTickets = event.custom_ticket_usages?.reduce((total, usage) => total + usage.used_tickets, 0) || 0;
  const usedTickets = event.used_tickets + customUsedTickets;

  const getTimeText = () => {
    if (!event.time) {
      return '';
    }
    return `${moment.unix(event.time).tz('utc').format(timeFormat)} - ${moment
      .unix(event.time + event.duration)
      .tz('utc')
      .format(timeFormat)}`;
  };
  const formatEmployees = () => {
    if (event.employee_ids.length) {
      return getEmployeeNames(event.employee_ids, users).map((x) => (
        <Grid key={`employee-${x}`} item color={'black'}>
          <Typography fontSize="12px">{x}</Typography>
        </Grid>
      ));
    }
    return '';
  };

  return viewType === 'month' ? (
    <Box
      className={`event-box month-event-box  ${highlight ? 'highlight' : ''}`}
      onClick={(e) => openEvent(event, e)}
      sx={{
        backgroundColor: orderPage.color || '#6577b3',
        opacity: usedTickets === 0 ? '0.6' : '1',
        border,
      }}>
      <Grid
        container
        flexDirection="column"
        className="text-center event-box-inner"
        sx={{ paddingTop: '4px', paddingBottom: '4px' }}>
        <Grid dir="ltr">{getTimeText()}</Grid>
        <Grid container item justifyContent="center" flexWrap="nowrap" alignItems="center" gap={0.5}>
          {formatEmployees()}
        </Grid>
        <Grid>{orderPage.label[language].slice(0, 50)}</Grid>
        <Grid item fontWeight="800">
          <Typography
            color={event.tickets <= usedTickets ? 'red' : 'white'}
            variant="caption"
            fontWeight={event.tickets <= usedTickets ? undefined : 700}>
            {usedTickets}/{event.tickets}
          </Typography>
        </Grid>
      </Grid>
    </Box>
  ) : viewType === 'condensed' ? (
    <Grid
      color="white"
      alignItems="center"
      justifyContent="center"
      border={border}
      onClick={(e) => openEvent(event, e)}
      borderRadius="8px"
      sx={{
        backgroundColor: orderPage.color || '#6577b3',
        cursor: 'pointer',
      }}>
      <Grid item container flexDirection="column">
        <Grid container p={1} alignItems="center" flexDirection="column">
          <Grid dir="ltr">
            <Typography variant="subtitle2" fontWeight={800}>
              {moment(event.date).format('ddd, MM.DD')}
            </Typography>
          </Grid>
          <Grid dir="ltr">
            <Typography variant="subtitle2" fontWeight={300}>
              {getTimeText()}
            </Typography>
          </Grid>
          <Grid container item justifyContent="center" flexWrap="nowrap" alignItems="center" gap={0.5}>
            {formatEmployees()}
          </Grid>
          {!hasOnlyOneOrderPage ? (
            <Grid>
              <Typography variant="subtitle2" fontWeight={300}>
                {orderPage.label[language].slice(0, 50)}
              </Typography>
            </Grid>
          ) : null}
        </Grid>

        <Grid
          item
          pt={1}
          pb={0.5}
          alignItems="center"
          container
          style={{
            background: 'rgba(255, 255, 255, 0.2)',
            width: '100%',
            boxShadow: '0px -1px 8px rgb(30 30 30 / 15%)',
          }}>
          <LinearProgressWithLabel value={usedTickets} total={event.tickets} />
        </Grid>
      </Grid>
    </Grid>
  ) : (
    <Grid
      className={` ${highlight ? 'highlight' : ''}`}
      ref={shouldNotDragNDrop ? undefined : setNodeRef}
      {...(shouldNotDragNDrop ? {} : attributes)}
      {...(shouldNotDragNDrop ? {} : listeners)}
      container
      onClick={(e) => (isDragging ? undefined : openEvent(event, e))}
      position="absolute"
      sx={{
        transform: transform ? `translate3d(${transform.x}px, ${transform.y}px, 0)` : undefined,
        transition: isDragging ? 'none' : 'transform 200ms ease',
        zIndex: isDragging ? 1000 : 'auto', // Bring the item to the front while dragging
        width: `calc(${css.width}% - 2px)`,
        left: `${css.left}%`,
        top: `${css.top}px`,
        height: `${css.height}px`,
        backgroundColor: orderPage.color || '#6577b3',
        opacity: usedTickets === 0 ? '0.6' : '1',
        border,
        cursor: 'pointer',
      }}
      mx="1px"
      borderRadius="8px">
      <Grid container alignItems="center" justifyContent="flex-start" flexDirection="column" gap={0.5} p={0.5}>
        {!isEmpty(event.custom_details) && (
          <Grid item style={{ position: 'absolute', top: 0, left: 0, zIndex: 1 }} fontSize="10px">
            {event.custom_details[0].label[language]}
          </Grid>
        )}
        <Grid container item justifyContent="center" flexWrap="nowrap" alignItems="center" gap={0.5}>
          {formatEmployees()}
        </Grid>
        <Grid item color="white" fontSize="10px">
          {orderPage.label[language].slice(0, 50)}
        </Grid>
      </Grid>

      <Grid
        container
        style={{
          position: 'absolute',
          height: '28px',
          bottom: 0,
          background: 'rgba(255, 255, 255, 0.2)',
          width: '100%',
          boxShadow: '0px -1px 8px rgb(30 30 30 / 15%)',
        }}>
        <LinearProgressWithLabel value={usedTickets} total={event.tickets} />
      </Grid>
    </Grid>
  );
};

export default EventBox;
