import React from 'react';
import { Grid, ToggleButton, ToggleButtonGroup, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Field, FieldProps, FormikHelpers } from 'formik';
import { AccountData, HolidayCalendar, WorkDayType, WorkingDaysData, WorkingHoursData } from '../../api';
import { useLanguage } from '../../common/GeneralUtils';
import { WorkingHours } from './WorkingHours';
import { useAuth } from '../../session/InternalAuthProvider';

function getDayName(englishDayName: string, language: 'en' | 'he') {
  if (language === 'en') {
    return englishDayName;
  }
  switch (englishDayName) {
    case 'sunday':
      return 'ראשון';
    case 'monday':
      return 'שני';
    case 'tuesday':
      return 'שלישי';
    case 'wednesday':
      return 'רביעי';
    case 'thursday':
      return 'חמישי';
    case 'friday':
      return 'שישי';
    case 'saturday':
      return 'שבת';
    default:
      return englishDayName;
  }
}

type WorkingDaysProps = {
  workingDays?: WorkingDaysData;
  setFieldValue: FormikHelpers<{ working_days?: WorkingDaysData }>['setFieldValue'];
  initialWorkingDays?: WorkingDaysData;
  isSubmitting: boolean;
  hideFixedOption?: boolean;
  title?: string;
  readOnly: boolean;
};
export const WorkingDays = ({
  readOnly,
  workingDays,
  setFieldValue,
  initialWorkingDays,
  isSubmitting,
  hideFixedOption,
  title,
}: WorkingDaysProps) => {
  const { t } = useTranslation();
  const formIsDisabled = readOnly || isSubmitting;
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const language = useLanguage();
  if (!workingDays) {
    return null; // events don't have working hours
  }
  const onWorkingDayTypeChange = (
    newWorkDayType: WorkDayType,
    fieldValueIndex: string,
    workingHoursData: WorkingHoursData,
  ) => {
    if (!newWorkDayType) {
      return;
    }
    const newWorkingHours: Partial<WorkingHoursData> = {
      ...workingHoursData,
      work_day_type: newWorkDayType,
    };
    if (newWorkDayType === WorkDayType.INTERVAL) {
      newWorkingHours.from_time = 32400;
      newWorkingHours.to_time = 61200;
    } else if (newWorkDayType === WorkDayType.FIXED) {
      newWorkingHours.start_seconds = [];
    } else {
      newWorkingHours.from_time = 0;
      newWorkingHours.to_time = 0;
    }
    void setFieldValue(fieldValueIndex, newWorkingHours);
  };
  return (
    <Grid container flexDirection="column" gap={1}>
      {title && (
        <Typography variant="h5" fontWeight={700}>
          {title}
        </Typography>
      )}

      {Object.entries(workingDays).map((x) => {
        const workingDayName = x[0];
        const workingHoursData = x[1];
        const fieldValueIndex = `working_days.${workingDayName}`;
        // @ts-ignore
        const initialWorkingHours = initialWorkingDays?.[workingDayName];
        const changedField = initialWorkingHours?.work_day_type !== workingHoursData.work_day_type;
        // @ts-ignore
        const dayName = getDayName(workingDayName, language).capitalize();
        return (
          <Grid
            container
            gap={2}
            alignItems={isSmallScreen ? 'flex-start' : 'center'}
            flexWrap="nowrap"
            border={isSmallScreen ? '1px solid #bfbfbf' : undefined}
            borderRadius={isSmallScreen ? '4px' : undefined}
            p={isSmallScreen ? 1 : undefined}>
            <Typography
              fontWeight={600}
              width={account.holiday_calendar === HolidayCalendar.ISR ? 80 : 100}
              color="grey">
              {dayName}
            </Typography>
            <Field name={fieldValueIndex}>
              {({ field }: FieldProps<any>) => (
                <Grid container gap={2} alignItems="center">
                  <ToggleButtonGroup
                    disabled={formIsDisabled}
                    style={{ border: changedField ? '2px solid #47b406' : undefined }}
                    value={workingHoursData.work_day_type}
                    exclusive
                    onChange={(e: any, newWorkDayType: WorkDayType) =>
                      onWorkingDayTypeChange(newWorkDayType, fieldValueIndex, workingHoursData)
                    }>
                    <ToggleButton size="small" value={WorkDayType.NOT_WORKING} style={{ textTransform: 'none' }}>
                      <Typography>
                        {hideFixedOption ? t('userAvailability.blocked') : t('workingDays.notWorking')}
                      </Typography>
                    </ToggleButton>
                    {!hideFixedOption && (
                      <ToggleButton size="small" value={WorkDayType.FIXED} style={{ textTransform: 'none' }}>
                        <Typography>{t('workingDays.fixed')}</Typography>
                      </ToggleButton>
                    )}
                    <ToggleButton size="small" value={WorkDayType.INTERVAL} style={{ textTransform: 'none' }}>
                      <Typography>{t('workingDays.interval')}</Typography>
                    </ToggleButton>
                  </ToggleButtonGroup>
                  <Grid item>
                    <WorkingHours
                      readOnly={readOnly}
                      workingHoursData={workingHoursData}
                      isSubmitting={isSubmitting}
                      fieldValueDayIndex={fieldValueIndex}
                      initialWorkingHours={initialWorkingHours}
                    />
                  </Grid>
                </Grid>
              )}
            </Field>
          </Grid>
        );
      })}
    </Grid>
  );
};
