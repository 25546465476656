import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import {
  Grid,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableRowProps,
  useMediaQuery,
  useTheme,
} from '@mui/material';
import React from 'react';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { omit } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import Typography from '@mui/material/Typography';
import { UploadWidgetResult } from '@bytescale/upload-widget';
import { RemoveCircleOutline } from '@mui/icons-material';
import { KeyedMutator } from 'swr/_internal';
import { styled } from '@mui/system';
import { AccountData, AccountService, OrderPageData, OrderPageTypes } from '../../../api';
import { OrderPageUpdate } from '../../OrderPageSettingsPage';
import { UseAuthPermissions } from '../../../session/UseAuthPermissions';
import { FormFieldColorPicker } from '../../formComponents/FormFieldColorPicker';
import { FormTextField } from '../../formComponents/FormTextField';
import { ImageUploadWidget } from '../../../emailTemplates/templateEditor/components/bytescale/ImageUploadWidget';
import { LocationPicker } from './LocationPicker';
import { FormCheckbox } from '../../formComponents/FormCheckBox';

type SettingsSectionProps = {
  orderPage: OrderPageData;
  orderPageInitialValues: OrderPageUpdate;
  updateLocalAccount: KeyedMutator<AccountData>;
  setIsUpdatedSnackOpen: (value: boolean) => void;
};

export type SettingsFormValues = {
  color: string;
  duration: number;
  min_date_in_days?: number;
  max_date_in_days: number;
  after_buffer_minutes: number;
  photo?: string;
  location_id?: string;
  wait_list_enabled?: boolean;
};

const StyledTableRow = styled((props: TableRowProps) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  if (isSmallScreen) {
    return (
      <TableRow
        {...props}
        sx={{
          marginBottom: 3,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
        }}
      />
    );
  }

  return <TableRow {...props} />;
})({});

const StyledBoldHeader = styled((props: TableRowProps) => {
  return <Typography {...props} fontWeight="bold" />;
})({});
export const SettingsSection = ({
  orderPage,
  orderPageInitialValues,
  updateLocalAccount,
  setIsUpdatedSnackOpen,
}: SettingsSectionProps) => {
  const { t } = useTranslation();
  const { readOnly } = UseAuthPermissions();
  const posthog = usePostHog();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const isEventOrderPage = orderPage.order_page_type === OrderPageTypes.EVENT;
  const schema = {
    color: yup.string().required(t('editOrderPage.yup.required')),
    duration: yup.number().required(t('editOrderPage.yup.required')).min(30, t('editOrderPage.yup.hasToBeMoreThan30')),
  };
  const privateOrderSpecificSchema = {
    min_date_in_days: yup.number().min(0, t('editOrderPage.yup.hasToBePoitive')),
    max_date_in_days: yup
      .number()
      .required(t('editOrderPage.yup.required'))
      .positive(t('editOrderPage.yup.hasToBePoitive'))
      .lessThan(181, t('editOrderPage.yup.hasToBeLessThan180')),
    after_buffer_minutes: yup
      .number()
      .required(t('editOrderPage.yup.required'))
      .min(0, t('editOrderPage.yup.hasToBePoitive')),
  };

  const validationSchema = yup.object().shape(isEventOrderPage ? schema : { ...schema, ...privateOrderSpecificSchema });

  const initialValues: SettingsFormValues = {
    color: orderPage?.color || '#6577b3',
    duration: (orderPage?.duration || 0) / 60,
    min_date_in_days: orderPage.min_date_in_days,
    max_date_in_days: orderPage.max_date_in_days,
    after_buffer_minutes: orderPage.after_buffer_minutes,
    photo: orderPage.photo,
    location_id: orderPage.location_id,
    wait_list_enabled: orderPage.waiting_list?.enabled,
  };

  const handleSubmit = async (values: SettingsFormValues) => {
    try {
      posthog.capture('Editing settings', { section: 'settingsSection', settings: values });
      const updatedAccount = await AccountService.updateOrderPage(
        omit(
          {
            ...orderPageInitialValues,
            ...values,
            id: orderPage.id,
            duration: (values.duration || 0) * 60,
          },
          'availability_settings.restrictions',
          'objects',
        ),
      );
      await updateLocalAccount(updatedAccount);
      setIsUpdatedSnackOpen(true);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({ values, isSubmitting, dirty, handleSubmit, setFieldValue }) => {
        const formIsDisabled = readOnly || isSubmitting;
        return (
          <Form onSubmit={handleSubmit}>
            <Grid
              container
              flexDirection={isSmallScreen ? 'column-reverse' : 'row'}
              justifyContent={isSmallScreen ? undefined : 'space-between'}
              flexWrap="wrap"
              alignItems="flex-start">
              <Grid
                container
                justifyContent={isSmallScreen ? undefined : 'flex-end'}
                mt={isSmallScreen ? 2 : undefined}>
                <LoadingButton
                  size="large"
                  loading={isSubmitting}
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={!dirty || formIsDisabled}>
                  {t('editOrderPage.orderPageBufferSubmit')}
                </LoadingButton>
              </Grid>

              <TableContainer>
                <Table>
                  <TableBody>
                    <StyledTableRow>
                      <TableCell>
                        <StyledBoldHeader>{t('editOrderPage.address')}</StyledBoldHeader>
                      </TableCell>
                      <TableCell>
                        <LocationPicker
                          initialLocationId={initialValues.location_id}
                          onSelected={(selectedLocation) => setFieldValue('location_id', selectedLocation)}
                          withoutLabel
                        />
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>
                        <StyledBoldHeader>{t('editOrderPage.orderPageColor')}</StyledBoldHeader>
                      </TableCell>
                      <TableCell>
                        <FormFieldColorPicker
                          tooltip={t('editOrderPage.orderPageColortooltip')}
                          disabled={formIsDisabled}
                          fieldName="color"
                        />
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>
                        <StyledBoldHeader>{t('editOrderPage.photo')}</StyledBoldHeader>
                      </TableCell>
                      <TableCell>
                        {values.photo && values.photo.length > 0 ? (
                          <Grid container alignItems="center">
                            <Grid border="1px solid black">
                              <img
                                src={values.photo?.replace('raw', 'image').concat('?w=200&h=200&fit=crop')}
                                width={isSmallScreen ? '120px' : '200px'}
                                alt="logo"
                              />
                            </Grid>
                            <Grid>
                              <IconButton
                                onClick={() => setFieldValue('photo', undefined)}
                                style={{ width: '48px', height: '48px' }}>
                                <RemoveCircleOutline color={'disabled'} style={{ fontSize: '32px' }} />
                              </IconButton>
                            </Grid>
                          </Grid>
                        ) : (
                          <ImageUploadWidget
                            onImageUpload={(files: UploadWidgetResult[]) => setFieldValue('photo', files[0].fileUrl)}
                          />
                        )}
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>
                        <StyledBoldHeader>{t('editOrderPage.orderPageMinDatePrefix')}</StyledBoldHeader>
                      </TableCell>
                      <TableCell>
                        <Grid container gap={1} alignItems="center">
                          <Grid item>
                            <FormTextField
                              isFieldUpdate
                              type="number"
                              width={60}
                              fieldName="min_date_in_days"
                              disabled={formIsDisabled}
                            />
                          </Grid>
                          <Grid item>
                            <Typography>{t('editOrderPage.orderPageMinDatePostFix')}</Typography>
                          </Grid>
                          <Grid item>
                            <FormTextField
                              isFieldUpdate
                              width={70}
                              fieldName="max_date_in_days"
                              disabled={formIsDisabled}
                              type="number"
                            />
                          </Grid>{' '}
                          <Grid item>
                            <Typography>{t('editOrderPage.orderPageMaxDatePostFix')}</Typography>
                          </Grid>
                        </Grid>
                      </TableCell>
                    </StyledTableRow>
                    {!isEventOrderPage && (
                      <StyledTableRow>
                        <TableCell>
                          <StyledBoldHeader>{t('editOrderPage.orderPageBuffer')}</StyledBoldHeader>
                        </TableCell>
                        <TableCell>
                          <FormTextField
                            width={100}
                            type="number"
                            isFieldUpdate
                            caption={t('editOrderPage.orderPageBufferCaption')}
                            fieldName="after_buffer_minutes"
                            disabled={formIsDisabled}
                          />
                        </TableCell>
                      </StyledTableRow>
                    )}
                    <StyledTableRow>
                      <TableCell>
                        <StyledBoldHeader>{t('editOrderPage.orderPageBufferDuration')}</StyledBoldHeader>
                      </TableCell>
                      <TableCell>
                        <FormTextField
                          width={100}
                          isFieldUpdate
                          fieldName="duration"
                          disabled={formIsDisabled}
                          type="number"
                        />
                      </TableCell>
                    </StyledTableRow>
                    <StyledTableRow>
                      <TableCell>
                        <StyledBoldHeader>{t('editOrderPage.waitlist')}</StyledBoldHeader>
                      </TableCell>
                      <TableCell>
                        <FormCheckbox
                          fieldName={'wait_list_enabled'}
                          disabled={formIsDisabled}
                          isFieldUpdate
                          label={t('editOrderPage.waitListEnabled')}
                        />
                      </TableCell>
                    </StyledTableRow>
                  </TableBody>
                </Table>
              </TableContainer>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
