import { cloneDeep } from 'lodash';
import { DateAvailabilityOut, DateAvailabilityOutMultipleOrderPages } from '../../api';

export function getAvailableDates(workshops: DateAvailabilityOutMultipleOrderPages[]): DateAvailabilityOut[] {
  const mergedAvailability: DateAvailabilityOut[] = [];
  const copiedWorkshops = cloneDeep(workshops);
  copiedWorkshops.forEach((workshop) => {
    workshop.availability.forEach((dateAvailability: DateAvailabilityOut) => {
      const existingDate = mergedAvailability.find((item) => item.date === dateAvailability.date);
      if (!existingDate) {
        mergedAvailability.push(dateAvailability);
      } else if (existingDate.isDisabled) {
        Object.assign(existingDate, dateAvailability);
      }
    });
  });

  return mergedAvailability;
}
