// Sample event data
// This is used so we will not display all hours.

import { EnrichedEventData, NoteData, OrderData, PrivateSlotOut, ShiftOut, UnavailabilityData } from '../../api';
import { UnifiedShiftAndUserAvailability } from '../UnifiedShiftAndUserAvailability';

const HOUR_HEIGHT = 50;
const WINTER_HOURS_START_OFFSET = 5;

type Item = { time?: any; start_time?: any; end_time?: any; duration?: any };

type UnifiedData =
  | UnavailabilityData
  | EnrichedEventData
  | OrderData
  | UnifiedShiftAndUserAvailability
  | ShiftOut
  | NoteData
  | PrivateSlotOut;

export type ItemCssDimension = {
  top: number;
  height: number;
  width: number;
  left: number;
};
type ItemCss = {
  top: number;
  height: number;
  width: number;
  left: number;
} & { itemId: string };

const getItemStartTime = (item: Item) => {
  return item.time || item.start_time;
};

const getItemEndTime = (item: Item) => {
  // Not proud of it. but hey! fuck you!
  return item.end_time || item.time + item.duration;
};
const getItemWidth = (amountOfGroups: number) => {
  return 100 / amountOfGroups;
};
const getItemCss = (
  item: UnifiedData,
  leftShift: number,
  amountOfGroups: number,
  shouldHaveWinterTimeOffset: boolean,
): ItemCssDimension => {
  const totalOffset = shouldHaveWinterTimeOffset ? WINTER_HOURS_START_OFFSET : WINTER_HOURS_START_OFFSET + 1;
  let top = HOUR_HEIGHT * (getItemStartTime(item) / 3600 - totalOffset);

  if (top < 0) {
    top = 0;
  }
  return {
    top,
    height: ((getItemEndTime(item) - getItemStartTime(item)) / 3600) * HOUR_HEIGHT,
    width: getItemWidth(amountOfGroups),
    left: leftShift === 0 ? 0 : (100 / amountOfGroups) * leftShift,
  };
};
const getInternalShiftItemCss = (item: UnifiedShiftAndUserAvailability): ItemCssDimension => {
  const startTimeShift = item.userAvailability ? item.userAvailability.start_time! : 0;
  const startTime = (item.shift!.start_time - startTimeShift) / 3600;
  const height = (item.shift!.end_time - item.shift!.start_time) / 3600;
  let top = HOUR_HEIGHT * startTime;
  if (top < 0) {
    top = 0;
  }
  return {
    top,
    height: height * HOUR_HEIGHT,
    width: 100,
    left: 0,
  };
};
const getInternalOrderOrEventItemCss = (
  item: OrderData | EnrichedEventData,
  unifiedShiftAndUserAvailability: UnifiedShiftAndUserAvailability,
): ItemCssDimension => {
  const startTime = (item.time! - unifiedShiftAndUserAvailability.start_time) / 3600;
  const height = item.duration / 3600;

  let top = HOUR_HEIGHT * startTime;
  if (top < 0) {
    top = 0;
  }
  return {
    top,
    height: height * HOUR_HEIGHT,
    width: 100,
    left: 0,
  };
};

function eventsOverlap(eventA: Item, eventB: Item): boolean {
  return (
    getItemStartTime(eventA) < getItemEndTime(eventB) && // Event A's start time is before Event B's end time
    getItemEndTime(eventA) > getItemStartTime(eventB) // Event A's end time is after Event B's start time
  );
}

const isUnifiedShiftAndUserAvailability = (event: UnifiedData): event is UnifiedShiftAndUserAvailability => {
  return (event as UnifiedShiftAndUserAvailability).user_id !== undefined;
};

export const getCalendarCellLocations = (
  orders: OrderData[],
  events: EnrichedEventData[],
  unavailabilities: UnavailabilityData[],
  shiftsAndUserAvailabilities: UnifiedShiftAndUserAvailability[],
  shouldHaveWinterTimeOffset: boolean,
  notes: NoteData[],
  privateSlotAvailabilities: PrivateSlotOut[],
) => {
  const unified_list: UnifiedData[] = [
    ...orders,
    ...events,
    ...unavailabilities,
    ...shiftsAndUserAvailabilities,
    ...notes,
    ...privateSlotAvailabilities,
  ];

  // Sort events by start time
  unified_list.sort((a, b) => getItemStartTime(a) - getItemStartTime(b));

  // Initialize groups
  const eventGroups: UnifiedData[][] = [];

  // Group overlapping events
  unified_list.forEach((event) => {
    let added = false;
    for (const group of eventGroups) {
      // Check if the event overlaps with any event in the group
      if (group.every((groupEvent) => !eventsOverlap(event, groupEvent))) {
        group.push(event);
        added = true;
        break;
      }
    }
    if (!added) {
      eventGroups.push([event]);
    }
  });

  // Sort eventGroups based on the number of ShiftOut objects in each group
  // why? so shifts are aligned to the beging of the date cell
  eventGroups.sort((a, b) => {
    const shiftCountA = a.filter((event) => isUnifiedShiftAndUserAvailability(event)).length;
    const shiftCountB = b.filter((event) => isUnifiedShiftAndUserAvailability(event)).length;

    return shiftCountA === shiftCountB ? a.length - b.length : shiftCountB - shiftCountA;
  });
  const cssResult: ItemCss[] = [];

  // Calculate event positions within groups
  eventGroups.forEach((group, groupIndex) => {
    group.forEach((item) => {
      // groupIndex cause each group has it's own index and it's own vertical slot.
      // eventGroups.length to know how many slots
      cssResult.push({
        itemId: item.id || '',
        ...getItemCss(item, groupIndex, eventGroups.length, shouldHaveWinterTimeOffset),
      });
      if (isUnifiedShiftAndUserAvailability(item) && (item.shift || item.userAvailability)) {
        item.shiftCss = item.shift ? getInternalShiftItemCss(item) : undefined;
        const ordersCss: { id: string; css: ItemCssDimension }[] = [];
        item.orders?.forEach((x) => {
          ordersCss.push({ id: x.id, css: getInternalOrderOrEventItemCss(x, item) });
        });
        item.ordersCss = ordersCss;
        const eventsCss: { id: string; css: ItemCssDimension }[] = [];
        item.events?.forEach((x) => {
          eventsCss.push({ id: x.id, css: getInternalOrderOrEventItemCss(x, item) });
        });
        item.eventsCss = eventsCss;
      }
    });
  });

  return cssResult;
};
