import React from 'react';
import { CacheProvider } from '@emotion/react';
import { Grid, ThemeProvider } from '@mui/material';
import { StaticDatePicker } from '@mui/x-date-pickers';
import merge from 'lodash/merge';
import { cacheLtr, cacheRtl, ltrTheme, rtlTheme } from '../../../../common/GeneralUtils';

type CustomStaticDatePickerProps = {
  referenceDate: any;
  displayStaticWrapperAs: 'desktop' | 'mobile';
  openTo: 'day' | 'month' | 'year';
  disablePast: boolean;
  value: any;
  minDate: any;
  maxDate: any;
  slots: any;
  slotProps: any;
  onChange: (date: any) => void;
  shouldDisableDate: (date: any) => boolean;
  theme: any;
  dir: 'ltr' | 'rtl';
};

const CustomStaticDatePicker: React.FC<CustomStaticDatePickerProps> = ({
  referenceDate,
  displayStaticWrapperAs,
  openTo,
  disablePast,
  value,
  minDate,
  maxDate,
  slots,
  slotProps,
  onChange,
  shouldDisableDate,
  theme,
  dir,
}) => {
  return (
    <CacheProvider value={dir === 'rtl' ? cacheRtl : cacheLtr}>
      <ThemeProvider theme={merge(theme, dir === 'rtl' ? rtlTheme : ltrTheme)}>
        <Grid container justifyContent="center" mt={2}>
          <StaticDatePicker
            sx={{
              '.MuiPickersCalendarHeader-label': {
                fontSize: '16px',
                fontWeight: 'bold',
              },
              '.MuiDateCalendar-root': {
                backgroundColor: theme.customTheme.palette.background,
              },
              '.MuiPickersDay-dayWithMargin.': {
                color: theme.customTheme.palette.text.primary,
              },
              '.MuiPickersDay-root.Mui-disabled:not(.Mui-selected)': {
                color: theme.customTheme.palette.text.primary,
              },
              '.MuiDayCalendar-weekDayLabel': {
                color: theme.customTheme.palette.text.primary,
              },
              '.MuiPickersArrowSwitcher-button': {
                color: theme.customTheme.palette.text.primary,
              },
              '.MuiPickersArrowSwitcher-button.Mui-disabled': {
                color: theme.customTheme.palette.calendarPicker.disabledArrow,
              },
              '.MuiPickersCalendarHeader-switchViewButton': {
                color: theme.customTheme.palette.text.primary,
              },
              maxWidth: '150px',
              borderRadius: '12px',
              border: `1px solid ${theme.customTheme.palette.border}`,
            }}
            referenceDate={referenceDate}
            displayStaticWrapperAs={displayStaticWrapperAs}
            openTo={openTo}
            disablePast={disablePast}
            value={value}
            minDate={minDate}
            maxDate={maxDate}
            slots={slots}
            slotProps={slotProps}
            onChange={onChange}
            shouldDisableDate={shouldDisableDate}
          />
        </Grid>
      </ThemeProvider>
    </CacheProvider>
  );
};

export default CustomStaticDatePicker;
