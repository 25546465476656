import { Button, Checkbox, FormControlLabel, Grid, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import { Form, Formik } from 'formik';
import * as yup from 'yup';
import { CodeResponse, useGoogleLogin } from '@react-oauth/google';
import { KeyedMutator } from 'swr/_internal';
import { AccountData, AccountService, UpdateAccountSyncInput } from '../api';

type AccountSyncSettingsProps = {
  account: AccountData;
  updateLocalAccount: KeyedMutator<AccountData>;
  setIsUpdatedSnackOpen: (value: boolean) => void;
};
export const AccountSyncSettings = ({
  account,
  updateLocalAccount,
  setIsUpdatedSnackOpen,
}: AccountSyncSettingsProps) => {
  const { t } = useTranslation();
  const [isUpdatingSettings, setIsUpdatingSettings] = React.useState(false);

  const initialValues: UpdateAccountSyncInput = {
    should_send_unavailability_notifications: account?.should_send_unavailability_notifications || false,
    should_send_manager_email_notifications: account?.should_send_manager_email_notifications || false,
    google_integration_enabled: account?.google_integration_enabled || false,
  };

  const validationSchema = yup.object().shape({
    should_send_unavailability_notifications: yup.boolean().required(),
    should_send_manager_email_notifications: yup.boolean().required(),
    google_integration_enabled: yup.boolean(),
  });

  const onSubmit = async (values: UpdateAccountSyncInput) => {
    setIsUpdatingSettings(true);
    const updatedAccount = await AccountService.syncSettings(values);
    await updateLocalAccount(updatedAccount);
    setIsUpdatingSettings(false);
    setIsUpdatedSnackOpen(true);
  };

  const onLoginSuccess = async (codeResponse: CodeResponse) => {
    setIsUpdatingSettings(true);
    const updatedAccount = await AccountService.accountGcalConnect({
      auth_code: codeResponse.code,
    });
    await updateLocalAccount(updatedAccount);
    setIsUpdatingSettings(false);
  };

  const disconnectFromGoogle = async () => {
    setIsUpdatingSettings(true);
    const updatedAccount = await AccountService.syncSettings({
      google_integration_enabled: false,
      should_send_unavailability_notifications: account.should_send_unavailability_notifications,
    });
    await updateLocalAccount(updatedAccount);
    setIsUpdatingSettings(false);
  };

  const login = useGoogleLogin({
    onSuccess: onLoginSuccess,
    scope: 'https://www.googleapis.com/auth/calendar',
    flow: 'auth-code',
    include_granted_scopes: false,
  });

  return (
    <Formik enableReinitialize initialValues={initialValues} validationSchema={validationSchema} onSubmit={onSubmit}>
      {({ values, handleChange, handleSubmit }) => (
        <Form onSubmit={handleSubmit}>
          <Grid container p={0} flexWrap="nowrap" alignItems="flex-start">
            <Grid container flexDirection="column">
              <Grid item mb={2}>
                <Typography variant="h4">{t('accountSettings.title')}</Typography>
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="should_send_unavailability_notifications"
                      checked={values.should_send_unavailability_notifications}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={<Typography>{t('accountSettings.sendUnavliabilityNotifications')}</Typography>}
                />
              </Grid>
              <Grid item>
                <FormControlLabel
                  control={
                    <Checkbox
                      name="should_send_manager_email_notifications"
                      checked={values.should_send_manager_email_notifications}
                      onChange={handleChange}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  }
                  label={<Typography>{t('userSettings.orderEmailSync')}</Typography>}
                />
              </Grid>
              <Grid container flexDirection="column" gap={2} mt={4}>
                <Typography variant="h4">{t('userSettings.whereToSync')}</Typography>

                {account?.google_integration_enabled ? (
                  <Grid container alignItems="center" gap={2}>
                    <img src={'/images/gcallogo.png'} width="24px" />
                    <Typography>
                      {t('userSettings.gcalSyncEmail', {
                        userEmail: account.gcal_synced_email,
                      })}
                    </Typography>
                    <Grid item mx={4}>
                      <Button variant="outlined" onClick={disconnectFromGoogle}>
                        {t('userSettings.disconnect')}
                      </Button>
                    </Grid>
                  </Grid>
                ) : (
                  <Grid container flexDirection="column" gap={2}>
                    <Typography>
                      {t('userSettings.userEmailSync', {
                        userEmail: account?.notifications_email,
                      })}
                    </Typography>
                    <Typography>{t('userSettings.googleSync')}:</Typography>
                    <Grid item width={'250px'} mt={-1}>
                      <Button
                        onClick={login}
                        variant="outlined"
                        style={{ borderRadius: '100px', borderColor: 'black' }}>
                        <Grid container dir="ltr" gap={1} mx="8px" my="4px">
                          <img src={'/images/gcallogo.png'} style={{ width: '24px' }} />
                          <Typography variant="button" style={{ textTransform: 'none' }} color="black">
                            Connect Google Calendar
                          </Typography>
                        </Grid>
                      </Button>
                    </Grid>
                  </Grid>
                )}
              </Grid>
            </Grid>
            <Grid container justifyContent="flex-end" mt={2} xs={2}>
              <Button type="submit" variant="contained" disabled={isUpdatingSettings}>
                {t('save')}
              </Button>
            </Grid>
          </Grid>
        </Form>
      )}
    </Formik>
  );
};
