import { RedirectToLogin } from '@propelauth/react';
import { useAuth } from './InternalAuthProvider';

const AuthGuard = ({ children }: { children: any }) => {
  const {
    authState: { isAuthenticated, isInitialized },
  } = useAuth();

  if (!isAuthenticated && isInitialized) {
    return <RedirectToLogin />;
  }

  return children;
};

export default AuthGuard;
