import { useTranslation } from 'react-i18next';
import { useAuth } from '../../session/InternalAuthProvider';
import { AccountData } from '../../api';
import { accountHasEventOrderPages, accountHasPrivateOrderPages } from '../../common/AccountUtils';

export const useGetActionsCopy = () => {
  const { authState } = useAuth();
  const { t } = useTranslation();

  const account = authState.account as unknown as AccountData;

  const hasEventOrderPages = accountHasEventOrderPages(account);
  const hasPrivateOrderPages = accountHasPrivateOrderPages(account);
  return {
    privateWorkshopText: t('calendar.privateWorkshop'),
    eventWorkshopText: t('calendar.joiningWorkshop'),
    hasEventOrderPages,
    hasPrivateOrderPages,
  };
};
