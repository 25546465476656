import Typography from '@mui/material/Typography';
import { Grid } from '@mui/material';
import React from 'react';
import { useNavigate } from 'react-router-dom';

type WaitingListIndicatorProps = { date: string; amountOfItems: number };
export const WaitingListIndicator = ({ date, amountOfItems }: WaitingListIndicatorProps) => {
  const navigate = useNavigate();
  if (!amountOfItems || amountOfItems === 0) {
    return null;
  }
  const handleWaitListClick = () => {
    navigate(`/dashboard/waiting-list?date=${date}`);
  };
  return (
    <Grid
      key={`waitinglist-${date}`}
      item
      onClick={handleWaitListClick}
      sx={{
        cursor: 'pointer',
        width: '18px',
        height: '18px',
        borderRadius: '50%',
        border: '2px solid orange',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}>
      <Typography fontSize="10px">{amountOfItems}</Typography>
    </Grid>
  );
};
