import { Grid, useMediaQuery, useTheme } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { AccountData, HolidayCalendar, WorkDayType, WorkingHoursData } from '../../api';
import { FormTimePicker } from '../formComponents/FormTimePicker';
import { WorkingHoursFixed } from './WorkingHoursFixed';
import { useAuth } from '../../session/InternalAuthProvider';

type workingHoursProps = {
  workingHoursData: WorkingHoursData;
  isSubmitting: boolean;
  fieldValueDayIndex: string;
  initialWorkingHours?: WorkingHoursData;
  readOnly: boolean;
};

export const WorkingHours = ({
  workingHoursData,
  isSubmitting,
  fieldValueDayIndex,
  initialWorkingHours,
  readOnly,
}: workingHoursProps) => {
  const { t } = useTranslation();
  const formIsDisabled = readOnly || isSubmitting;
  const theme = useTheme();
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
  if (workingHoursData.work_day_type === WorkDayType.FIXED) {
    return (
      <WorkingHoursFixed
        fieldValueDayIndex={fieldValueDayIndex}
        isSubmitting={isSubmitting}
        initialStartSeconds={initialWorkingHours?.start_seconds}
      />
    );
  }
  if (workingHoursData.work_day_type === WorkDayType.NOT_WORKING) {
    return null;
  }
  return (
    <Grid container flexWrap="nowrap" flexDirection={isSmallScreen ? 'column' : 'row'}>
      <Grid display="flex" alignItems="center" justifyContent="space-between">
        <Grid item>
          <Typography>{t('workingDays.from')}</Typography>
        </Grid>
        <Grid item mx={1}>
          <FormTimePicker
            width={account.holiday_calendar === HolidayCalendar.ISR ? 70 : 100}
            disabled={formIsDisabled}
            fieldName={`${fieldValueDayIndex}.from_time`}
            isFieldUpdate
          />
        </Grid>
      </Grid>
      <Grid display="flex" alignItems="center" mt={isSmallScreen ? 1 : undefined} justifyContent="space-between">
        <Grid item>
          <Typography>{t('workingDays.to')}</Typography>
        </Grid>
        <Grid item mx={1}>
          <FormTimePicker
            width={account.holiday_calendar === HolidayCalendar.ISR ? 70 : 100}
            disabled={formIsDisabled}
            fieldName={`${fieldValueDayIndex}.to_time`}
            isFieldUpdate
          />
        </Grid>
      </Grid>
    </Grid>
  );
};
