import { Box, Grid } from '@mui/material';
import './OrderBox.scss';
import React, { useState } from 'react';
import Typography from '@mui/material/Typography';
import moment from 'moment';
import { isEmpty } from 'lodash';
import { useTranslation } from 'react-i18next';
import { useDroppable } from '@dnd-kit/core';
import { AccountData, EnrichedEventData, OrderData } from '../../api';
import { getEmployeeNames } from '../CalendarUtils';
import { useCalendar } from '../CalendarContext';
import { UnifiedShiftAndUserAvailability } from '../UnifiedShiftAndUserAvailability';
import ShiftBox from './ShiftBox';
import ShiftBoxPopper from './ShiftBoxPopper';
import EventBox from './EventBox';
import OrderBox from './OrderBox';
import { useAuth } from '../../session/InternalAuthProvider';
import { getTimeFormat } from '../../common/getDateFormat';

type UnifiedShiftsUserAvailabilitiesBoxProps = {
  shiftsAndUserAvailability: UnifiedShiftAndUserAvailability;
  css?: any;
  viewType: 'month' | 'day';
  date: string;
  openOrder: (event: any, order: OrderData) => void;
  openEvent: (event: EnrichedEventData) => void;
};
export const UnifiedShiftsUserAvailabilitiesBox = ({
  shiftsAndUserAvailability,
  css,
  viewType,
  date,
  openOrder,
  openEvent,
}: UnifiedShiftsUserAvailabilitiesBoxProps) => {
  const { setNodeRef, isOver } = useDroppable({
    id: shiftsAndUserAvailability.id,
  });
  const { t } = useTranslation();

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const openShift = (event: any) => {
    setAnchorEl(anchorEl ? null : event.currentTarget);
  };
  // @ts-ignore
  const { users, openEditShiftDialog, userColors } = useCalendar();
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const timeFormat = getTimeFormat(account);

  const shiftAndAvailabilityFullyOverlapping =
    shiftsAndUserAvailability.userAvailability?.start_time === shiftsAndUserAvailability.shift?.start_time &&
    shiftsAndUserAvailability.userAvailability?.end_time === shiftsAndUserAvailability.shift?.end_time;
  const hasBothShiftAndAvailability = !!shiftsAndUserAvailability.userAvailability && !!shiftsAndUserAvailability.shift;
  const openMainGridClicked = (e: any) => {
    if (!shiftsAndUserAvailability.shift) {
      openEditShiftDialog(undefined, {
        startTime: shiftsAndUserAvailability.start_time,
        endTime: shiftsAndUserAvailability.end_time,
        userId: shiftsAndUserAvailability.user_id,
        description: shiftsAndUserAvailability.userAvailability?.description,
        date,
      });
    } else if (
      shiftsAndUserAvailability.shift &&
      (!hasBothShiftAndAvailability || shiftAndAvailabilityFullyOverlapping)
    ) {
      openShift(e);
    }
  };
  const getTimeText = () => {
    if (shiftsAndUserAvailability.userAvailability?.available_all_day) {
      return t('userAvailability.allDay');
    }
    return `${moment.unix(shiftsAndUserAvailability.start_time).tz('utc').format('H:mm')}-${moment
      .unix(shiftsAndUserAvailability.end_time)
      .tz('utc')
      .format(timeFormat)}`;
  };

  return viewType === 'month' ? (
    // not yet supported
    <Box className="order-box month-order-box">
      <Box className="text-center">
        <p />
      </Box>
    </Box>
  ) : (
    <Grid
      ref={setNodeRef}
      sx={{
        width: `calc(${css.width}% )`,
        left: `${css.left}%`,
        marginTop: `${css.top}px`,
        height: `${css.height - 2}px`,
        border: isOver ? '3px solid green' : !account.shifts_as_employee_unavailability ? '1px solid black' : undefined,
        borderRadius: '4px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        cursor: !shiftAndAvailabilityFullyOverlapping ? undefined : 'pointer',
        backgroundColor:
          (!hasBothShiftAndAvailability && shiftsAndUserAvailability.shift) || shiftAndAvailabilityFullyOverlapping
            ? '#e0b06e4f'
            : undefined,
      }}
      onClick={openMainGridClicked}
      container
      alignItems="center"
      position="absolute"
      overflow="hidden">
      <Grid container flexDirection="column" alignItems="center" marginInlineStart={css.width < 50 ? -2 : undefined}>
        <Grid item>
          <Typography variant="subtitle2" color={userColors[shiftsAndUserAvailability.user_id]}>
            {getEmployeeNames([shiftsAndUserAvailability.user_id], users)[0]}
          </Typography>
        </Grid>
        <Typography variant="caption" fontWeight="100" p={1}>
          {getTimeText()}
        </Typography>
        {shiftsAndUserAvailability.userAvailability?.description && (
          <Typography variant="caption" fontWeight="100" p={1}>
            {shiftsAndUserAvailability.userAvailability?.description}
          </Typography>
        )}
        {shiftsAndUserAvailability.shift?.description && (
          <Typography variant="caption" fontWeight="100" mt={-1}>
            {shiftsAndUserAvailability.shift.description.slice(0, 30)}
          </Typography>
        )}
      </Grid>

      {shiftsAndUserAvailability.shift && (
        <ShiftBox
          isUnifiedShift
          viewType="day"
          key={shiftsAndUserAvailability.shift.id}
          shift={shiftsAndUserAvailability.shift}
          css={shiftsAndUserAvailability.shiftCss}
        />
      )}
      {shiftsAndUserAvailability.orders &&
        !isEmpty(shiftsAndUserAvailability.orders) &&
        shiftsAndUserAvailability.orders.map((order) => {
          return (
            <OrderBox
              viewType="day"
              key={order.id}
              order={order}
              css={shiftsAndUserAvailability.ordersCss?.find((x) => x.id === order.id)?.css}
              openOrder={(e) => {
                e.stopPropagation();
                openOrder(e, order);
              }}
            />
          );
        })}
      {shiftsAndUserAvailability.events &&
        !isEmpty(shiftsAndUserAvailability.events) &&
        shiftsAndUserAvailability.events.map((event) => {
          return (
            <EventBox
              viewType="day"
              key={event.id}
              event={event}
              css={shiftsAndUserAvailability.eventsCss?.find((x) => x.id === event.id)?.css}
              openEvent={(event, e) => {
                e.stopPropagation();
                openEvent(event);
              }}
            />
          );
        })}
      {shiftsAndUserAvailability.shift && (
        <ShiftBoxPopper anchorEl={anchorEl} setAnchorEl={setAnchorEl} shift={shiftsAndUserAvailability.shift} />
      )}
    </Grid>
  );
};
