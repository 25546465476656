import React, { useState } from 'react';
import { Grid, useMediaQuery } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { DatePicker } from '@mui/x-date-pickers';

import moment from 'moment';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { ReportsService } from '../api';
import { theme, useLanguage } from '../common/GeneralUtils';
import { DATE_FORMAT } from '../calendar/CalendarWrapper';

export const OrdersReports = () => {
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();
  const language = useLanguage();
  const [isLoading, setIsLoading] = useState(false);
  const [fromDate, setFromDate] = useState(moment().subtract(1, 'month').startOf('month'));
  const [toDate, setToDate] = useState(moment().subtract(1, 'month').endOf('month'));

  const handleDownloadCSV = async () => {
    try {
      setIsLoading(true);
      const response = await ReportsService.getOrdersReport(
        language,
        fromDate?.format(DATE_FORMAT),
        toDate?.format(DATE_FORMAT),
      );
      // Add BOM to the response to ensure proper encoding in Excel
      const bom = '\uFEFF';
      const csvContent = bom + response;
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8' });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `orders-${fromDate.format(DATE_FORMAT)}-${toDate.format(DATE_FORMAT)}.csv`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      window.URL.revokeObjectURL(url);
    } catch (error) {
      console.error('Error downloading the CSV file:', error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Grid container flexDirection="column" justifyContent="center" alignItems="center">
      <Typography variant={'h4'}>{t('ordersReport.title')}</Typography>
      <Typography variant={'caption'}>{t('ordersReport.subTitle')}</Typography>
      <Grid
        container
        flexDirection={isSmallScreen ? 'column' : 'row'}
        justifyContent="center"
        alignItems="center"
        spacing={2}
        mt={4}>
        <Grid item>
          <DatePicker
            label={t('ordersReport.from')}
            value={fromDate}
            onChange={(newValue: any) => setFromDate(newValue)}
            minDate={moment().subtract(6, 'months')}
          />
        </Grid>
        <Grid item>
          <DatePicker
            label={t('ordersReport.to')}
            value={toDate}
            onChange={(newValue: any) => setToDate(newValue)}
            minDate={fromDate}
            maxDate={moment().add(1, 'day')}
          />
        </Grid>
        <Grid item>
          <LoadingButton
            loading={isLoading}
            variant="contained"
            color="primary"
            onClick={handleDownloadCSV}
            disabled={toDate.isBefore(fromDate)}>
            {t('ordersReport.download')}
          </LoadingButton>
        </Grid>
      </Grid>
    </Grid>
  );
};
