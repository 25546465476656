import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Snackbar,
} from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ContentCopy } from '@mui/icons-material';
import { LinksService } from '../../api/services/LinksService';
import type { CreateLinkInput } from '../../api/models/CreateLinkInput';

const CopyToClipboardButton = ({
  text,
  IconComponent = ContentCopy,
  color,
  width,
  orderPageId,
  disabled,
}: {
  text: string;
  IconComponent?: any;
  color?: string;
  width?: string;
  orderPageId?: string;
  disabled?: boolean;
}) => {
  const { t } = useTranslation();
  const [isSuccessSnackbarOpen, setIsSuccessSnackbarOpen] = useState(false);
  const [isSmallScreenCopyOpen, setIsSmallScreenCopyOpen] = useState(false);
  const [shortLink, setShortLink] = useState('');

  const handleClick = async () => {
    if (disabled) {
      return;
    }
    if (!text.startsWith(window.location.origin)) {
      await navigator.clipboard.writeText(text);
      setIsSuccessSnackbarOpen(true);
      return;
    }
    try {
      const requestBody: CreateLinkInput = { long_link: text, order_page_id: orderPageId };
      const response = await LinksService.createShortLink(requestBody);
      const { short_code } = response;
      const shortLink = `${import.meta.env.VITE_REACT_APP_LINKS_HOST}/${short_code}`;
      setShortLink(shortLink);

      try {
        await navigator.clipboard.writeText(shortLink);
        setIsSuccessSnackbarOpen(true);
      } catch (error) {
        console.error('Failed to copy short link:', error);
        setIsSmallScreenCopyOpen(true);
      }
    } catch (error) {
      console.error('Failed to create short link:', error);
    }
  };

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(shortLink);
      setIsSmallScreenCopyOpen(false);
      setIsSuccessSnackbarOpen(true);
    } catch (error) {
      console.error('Failed to copy short link in fallback:', error);
    }
  };

  return (
    <>
      <IconComponent onClick={handleClick} cursor="pointer" style={{ color, width }} />
      <Snackbar
        open={isSuccessSnackbarOpen}
        onClose={() => setIsSuccessSnackbarOpen(false)}
        autoHideDuration={2000}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
        <Alert onClose={() => setIsSuccessSnackbarOpen(false)} severity="success">
          {t('copiedToClipboard')}
        </Alert>
      </Snackbar>
      <Dialog open={isSmallScreenCopyOpen} onClose={() => setIsSmallScreenCopyOpen(false)}>
        <DialogTitle>{t('copyToClipboard.copyFailed')}</DialogTitle>
        <DialogContent>
          <DialogContentText>{shortLink}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setIsSmallScreenCopyOpen(false)} color="primary">
            {t('close')}
          </Button>
          <Button onClick={handleCopy} color="primary" variant="contained">
            <Grid container gap={1}>
              <ContentCopy style={{ width: 16 }} />
              {t('copyToClipboard.copy')}
            </Grid>
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default CopyToClipboardButton;
