import { useField } from 'formik';
import { Grid } from '@mui/material';
import { MuiColorInput } from 'mui-color-input';
import React from 'react';
import Typography from '@mui/material/Typography';
import { StyledErrorMessage } from '../StyledErrorMessage';

export const FormFieldColorPicker = ({
  label,
  fieldName,
  disabled,
  tooltip,
}: {
  label?: string;
  fieldName: string;
  disabled: boolean;
  tooltip: string;
}) => {
  const [field, _, helpers] = useField(fieldName);

  const handleChange = (newValue: string) => {
    void helpers.setValue(newValue);
  };

  return (
    <Grid container alignItems="center" gap={1}>
      {label && (
        <Typography>
          <label htmlFor={fieldName}>{label}</label>
        </Typography>
      )}

      <Grid item xs={5} md={3}>
        <MuiColorInput
          disabled={disabled}
          {...field}
          value={field.value || ''}
          onChange={handleChange}
          format="hex"
          isAlphaHidden
        />
      </Grid>
      <Typography fontSize="12px" color="grey">
        {tooltip}
      </Typography>
      <StyledErrorMessage name={fieldName} />
    </Grid>
  );
};
