import { CalendarService } from '../../api';
import { useItems } from './useItems';

const fetcher = ({ startDate, endDate }: { startDate: string; endDate: string }) =>
  CalendarService.getCalendarWaitingList(startDate, endDate);

type useWaitingListProps = { startDate: string; endDate: string };

export const useWaitingList = ({ startDate, endDate }: useWaitingListProps) => {
  const { items, error, isLoading, deleteItem, addItems, updateItem, reFetch } = useItems({
    startDate,
    endDate,
    opId: 'getCalendarWaitingList',
    fetcher,
    refreshInterval: 600000,
  });

  return {
    items: items || {},
    isLoading,
    isError: error,
    deleteItem,
    reFetch,
    addItems,
    updateItem,
  };
};
