import React, { createContext, useContext, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Grid } from '@mui/material';
import { AccountData, AccountService } from '../api';
import { WidgetThemeProvider } from './theme/Theme';
import RotatingLoader from '../common/ui/RotatingLoader';
import { deepOmitNull } from './utils';
import { useLanguageChange } from './useLanguageChange';

const AccountDataContext = createContext<AccountData | undefined>(undefined);

export const useAccountData = () => {
  return useContext(AccountDataContext);
};

export const AccountSpecificThemeWrapper = ({ children }: { children: React.ReactNode }) => {
  const { accountId } = useParams();
  const [account, setAccount] = useState<AccountData | undefined>(undefined);
  const { isLoading: isLoadingLanguage } = useLanguageChange(account?.default_system_language);

  useEffect(() => {
    void (async () => {
      const accountData = (await AccountService.getAccount(accountId)) as AccountData;
      setAccount(accountData);
    })();
  }, [accountId]);

  if (!account || isLoadingLanguage) {
    return (
      <Grid container justifyContent="space-around" mt={3}>
        <Grid
          container
          minHeight={336}
          sx={{
            borderRadius: '12px',
            width: '322px',
          }}>
          <RotatingLoader />
        </Grid>
      </Grid>
    );
  }

  const externalTheme = deepOmitNull(account.custom_theme);

  return (
    <AccountDataContext.Provider value={account}>
      {/* @ts-ignore */}
      <WidgetThemeProvider remoteThemeOverride={externalTheme}>{children}</WidgetThemeProvider>
    </AccountDataContext.Provider>
  );
};
