import moment from 'moment';
import { getDateFormat, getTimeFormat } from '../../../common/getDateFormat';

const FinalDateDetails = (props) => {
  const { date, time, duration, account } = props;
  if (!date) {
    return null;
  }
  const timeFormat = getTimeFormat(account);
  const { longDateFormat } = getDateFormat(account);
  const endTime = moment(time);
  endTime.add(duration, 'seconds');

  return (
    <div className="final-date-details">
      {date.format(longDateFormat)}
      &nbsp;&nbsp;&nbsp;⋅&nbsp;&nbsp;&nbsp;
      <strong>
        {time.format(timeFormat)} - {endTime.format(timeFormat)}
      </strong>
    </div>
  );
};

export default FinalDateDetails;
