import React, { useState } from 'react';
import { Grid, IconButton, TextField, Tooltip } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { FormikState } from 'formik';
import Typography from '@mui/material/Typography';
import { Edit } from '@mui/icons-material';
import Button from '@mui/material/Button';
import Add from '@mui/icons-material/Add';
import CheckIcon from '@mui/icons-material/Check';
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import { useEditOrder } from './EditOrderContext';
import UiObjectsList from '../../../order_page/ui-objects/UiObjectsList';
import { useAuth } from '../../../session/InternalAuthProvider';
import { getCurrencySymbol } from '../../../order_page/utils';
import { AccountData, PaymentLinkPaymentStatus } from '../../../api';
import { AddPaymentItemDialog } from './components/experienceDetailPanel/AddPaymentItemDialog';
import RotatingLoader from '../../../common/ui/RotatingLoader';
import { usePriceRenderer } from '../../../common/usePriceRenderer';
import { usePriceFormatter } from '../../../common/usePriceFormatter';

export const getPaymentLinkStatus = (status: PaymentLinkPaymentStatus, translations: any) => {
  switch (status) {
    case PaymentLinkPaymentStatus.PAID:
      return (
        <Tooltip title={translations('calendar.edit_order.paymentLinkStatusPaid')} arrow>
          <CheckIcon style={{ color: '#0DF205' }} />
        </Tooltip>
      );
    case PaymentLinkPaymentStatus.PENDING_PAYMENT:
      return (
        <Tooltip title={translations('calendar.edit_order.paymentLinkStatusPending')} arrow>
          <HourglassEmptyIcon style={{ color: '#bababa' }} />
        </Tooltip>
      );

    default:
      return null;
  }
};
export const getLinkToPaymentLink = (paymentLinkId: string) =>
  `${window.location.origin}/payment-link?linkid=${paymentLinkId}`;

type EditOrderDetailsProps = {
  errors: FormikState<any>['errors'];
  isLoadingDates: boolean;
};

const OrderDetails = ({ errors, isLoadingDates }: EditOrderDetailsProps) => {
  const { authState } = useAuth();
  const {
    // @ts-ignore
    editOrderState,
    // @ts-ignore
    setEditOrderState,
    // @ts-ignore
    setOrderDetailValue,
    // @ts-ignore
    setCustomOrderDetailValue,
    // @ts-ignore
    setOrderCustomDetails,
    // @ts-ignore
    setPrice,
  } = useEditOrder();
  const priceFormatter = usePriceFormatter();

  const [loadingPaymentLinks, setLoadingPaymentLinks] = useState(false);

  const { t } = useTranslation();
  const [isCustomisingPrice, setIsCustomisingPrice] = useState(false);
  const isEditingExistingOrder = editOrderState.order_id !== '';
  const shouldHideEditIcon = editOrderState.shouldAllowPriceOverride
    ? false
    : isCustomisingPrice || isEditingExistingOrder;
  const orderState = editOrderState;
  const [isAddPaymentItemOpen, setIsAddPaymentItemOpen] = useState(false);

  const currencySymbol = getCurrencySymbol(authState.account as unknown as AccountData);
  const { FullPriceBreakdown, setPaymentLinks } = usePriceRenderer({
    orderId: editOrderState.order_id,
    discountId: editOrderState.discountId,
    initialPrice: editOrderState.details.price,
    returningCustomerFromOrderId: editOrderState.returningCustomerFromOrderId,
    extraPaymentLines: editOrderState.extraPaymentsLines,
  });
  return (
    <Grid container flexDirection="column">
      <UiObjectsList
        type="edit"
        isLoadingDates={isLoadingDates}
        orderState={editOrderState}
        setOrderState={setEditOrderState}
        setOrderDetailValue={setOrderDetailValue}
        setCustomOrderDetailValue={setCustomOrderDetailValue}
        setOrderCustomDetails={setOrderCustomDetails}
        errors={errors}
        isEditOrder
      />

      <Grid item container flexDirection="column" mt={0}>
        <Grid item container gap={1} alignItems="center">
          <Grid
            item
            container
            gap={1}
            alignItems="center"
            flexWrap="nowrap"
            borderRadius="8px"
            border="1px solid gray"
            p={1}
            mt={1}>
            <Grid container item gap={1} alignItems="center">
              <Typography>{t('calendar.edit_order.order_details.calculatedPrice')}</Typography>
              {priceFormatter(editOrderState.calculatedPrice)}
            </Grid>
            {shouldHideEditIcon ? null : (
              <IconButton onClick={() => setIsCustomisingPrice(true)}>
                <Edit />
              </IconButton>
            )}
          </Grid>
          {loadingPaymentLinks ? (
            <RotatingLoader />
          ) : (
            <>
              {isEditingExistingOrder && (
                <Grid item container justifyContent="space-between" alignItems="center" mt={2}>
                  <Typography fontWeight="700">{t('calendar.edit_order.order_details.details')}</Typography>
                  <Grid justifyContent="flex-end">
                    <Button
                      onClick={() => setIsAddPaymentItemOpen(true)}
                      variant="outlined"
                      style={{ textTransform: 'none' }}>
                      {t('calendar.edit_order.order_details.addExtraPayment')}
                      <Add />
                    </Button>
                  </Grid>
                </Grid>
              )}
              {isCustomisingPrice && (
                <Grid container alignItems="center" gap={1}>
                  <Typography>{t('calendar.edit_order.order_details.ovveridePrice')}</Typography>
                  <Grid item width="100px">
                    <TextField
                      type="number"
                      size="small"
                      id="title"
                      name="title"
                      variant="outlined"
                      value={orderState.details.price}
                      onChange={(e: any) => setPrice(+e.target.value || 0)}
                      InputProps={{
                        endAdornment: currencySymbol,
                        sx: {
                          color: 'black',
                        },
                      }}
                    />
                  </Grid>
                </Grid>
              )}
              {isEditingExistingOrder && (
                <Grid container width="100%">
                  <FullPriceBreakdown isCustomisingPrice={isCustomisingPrice} />
                </Grid>
              )}
            </>
          )}
        </Grid>
        <AddPaymentItemDialog
          accountId={orderState.account.id}
          orderId={orderState.order_id}
          setLoadingPaymentLinks={setLoadingPaymentLinks}
          setPaymentLinks={setPaymentLinks}
          setEditOrderState={setEditOrderState}
          editOrderState={orderState}
          setIsDialogOpen={setIsAddPaymentItemOpen}
          isDialogOpen={isAddPaymentItemOpen}
        />
      </Grid>
    </Grid>
  );
};

export default OrderDetails;
