import { Field, FieldProps } from 'formik';
import React from 'react';
import { Grid } from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers';
import moment from 'moment';
import { StyledErrorMessage } from '../StyledErrorMessage';
import { AccountData, HolidayCalendar } from '../../api';
import { useAuth } from '../../session/InternalAuthProvider';
import { FormElementProps, getShouldMarkFieldAsUpdated } from './utils';

type FormTimePickerProps = FormElementProps & {
  minTime?: moment.Moment;
  width?: number;
};

export const FormTimePicker = ({ disabled, fieldName, minTime, label, isFieldUpdate, width }: FormTimePickerProps) => {
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  return (
    <Grid container width={width} justifyContent="flex-start">
      <Grid item>
        <Field name={fieldName}>
          {({ field, form }: FieldProps<string | number>) => {
            const shouldMarkFieldAsUpdated = getShouldMarkFieldAsUpdated(form, fieldName, field, isFieldUpdate);

            return (
              <MobileTimePicker
                {...field}
                sx={{
                  '&& .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline': {
                    borderColor: shouldMarkFieldAsUpdated ? '#47b406' : undefined,
                    borderWidth: shouldMarkFieldAsUpdated ? '4px' : undefined,
                  },
                  '&& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                    borderColor: shouldMarkFieldAsUpdated ? '#47b406' : undefined,
                    borderWidth: shouldMarkFieldAsUpdated ? '4px' : undefined,
                  },
                }}
                value={moment.unix(+field.value).tz('utc')}
                ampm={account.holiday_calendar === HolidayCalendar.US}
                disabled={disabled}
                minutesStep={15}
                onChange={(newValue) =>
                  form.setFieldValue(fieldName, newValue!.hours() * 3600 + newValue!.minutes() * 60)
                }
                minTime={minTime}
                label={label}
              />
            );
          }}
        </Field>
        <StyledErrorMessage name={fieldName} />
      </Grid>
    </Grid>
  );
};
