import React from 'react';
import { Checkbox, FormControl, FormControlLabel, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AccountData, OrderPageData, UnavailabilityDetailData } from '../../../../api';
import { useLanguage } from '../../../../common/GeneralUtils';
import { FormCheckbox } from '../../../../experienceSettings/formComponents/FormCheckBox';
import { RenderCustomDetails } from './CustomDetailsRenderrer';
import { WorkshopCircle } from '../../../../common/ui/WorkshopCircle';
import { useAuth } from '../../../../session/InternalAuthProvider';

export const getOrderPageLabel = (language: 'en' | 'he', orderPageData: OrderPageData[], orderPageId: string) => {
  const orderPage = orderPageData.find((x) => x.id === orderPageId);
  if (orderPage) {
    return orderPage.label[language];
  }
  return '';
};

export const UnavailabilityOrderPagesMultiSelect = ({
  orderPages,
  unavailabilityDetails,
  setUnavailabilityDetails,
  isFieldUpdate,
}: {
  orderPages: OrderPageData[];
  unavailabilityDetails: UnavailabilityDetailData[];
  setUnavailabilityDetails: (selected: UnavailabilityDetailData[]) => void;
  isFieldUpdate: boolean;
}) => {
  const { t } = useTranslation();
  const language = useLanguage();
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const shouldBlockAllPages = unavailabilityDetails.length === orderPages.length;
  const updateCustomDetails = (order_page_id: string, key: string, value: any) => {
    const clonedDetails = [...unavailabilityDetails];

    const updatedDetails = clonedDetails.map((detail) => {
      if (detail.order_page_id === order_page_id) {
        return { ...detail, custom_details: { [key]: value } };
      }
      return detail;
    });

    setUnavailabilityDetails(updatedDetails);
  };

  return (
    <FormControl sx={{ minWidth: '250px' }}>
      <FormControlLabel
        control={
          <Checkbox
            indeterminate={!shouldBlockAllPages && unavailabilityDetails.length !== 0}
            checked={shouldBlockAllPages}
          />
        }
        label={t('calendar.unavailability_dialog.selectAllPages')}
        onChange={(e: any) => {
          setUnavailabilityDetails(
            e.target.checked ? orderPages.map((x: OrderPageData) => ({ order_page_id: x.id })) : [],
          );
        }}
      />
      <Grid container flexDirection="column" ml={3} gap={1}>
        {orderPages.map((orderPage) => {
          const checked = !!unavailabilityDetails.find((x) => x.order_page_id === orderPage.id);
          const detailIndex = unavailabilityDetails.findIndex((x) => x.order_page_id === orderPage.id);
          return (
            <FormCheckbox
              onChange={(e: any) => {
                if (e.target.checked) {
                  const newDetails = [...unavailabilityDetails, { order_page_id: orderPage.id }];
                  setUnavailabilityDetails(newDetails);
                } else {
                  setUnavailabilityDetails(unavailabilityDetails.filter((x) => x.order_page_id !== orderPage.id));
                }
              }}
              label={
                <Grid container flexDirection="column" borderRadius="8px" border="1px solid #c4c4c4" p={0.5} px={1}>
                  <Grid container alignItems="center" gap={1}>
                    <WorkshopCircle account={account} orderPageId={orderPage.id} language={language} />
                    <Grid item>{getOrderPageLabel(language, orderPages, orderPage.id)}</Grid>
                  </Grid>
                  <Grid item>
                    {checked && (
                      <RenderCustomDetails
                        orderPageId={orderPage.id}
                        updateCustomDetails={updateCustomDetails}
                        customDetails={
                          unavailabilityDetails.find((x) => x.order_page_id === orderPage.id)?.custom_details
                        }
                      />
                    )}
                  </Grid>
                </Grid>
              }
              disabled={false}
              fieldName={`unavailability_details.${detailIndex}`}
              isFieldUpdate={isFieldUpdate}
            />
          );
        })}
      </Grid>
    </FormControl>
  );
};
