import { Chip, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../orders/Orders.scss';
import '../orders/UpcomingOrders.scss';
import moment from 'moment/moment';
import { Link, useSearchParams } from 'react-router-dom';
import CancelIcon from '@mui/icons-material/Cancel';
import { AccountData, WaitingListItemData, WaitingListService } from '../api';
import { CellProps, useTable } from '../table/useTable';
import { useAuth } from '../session/InternalAuthProvider';
import { WorkshopCircle } from '../common/ui/WorkshopCircle';
import { useLanguage } from '../common/GeneralUtils';
import TimeUtils from '../common/TimeUtils';
import { getDateFormat, getTimeFormat } from '../common/getDateFormat';
import { CalendarView, DATE_FORMAT } from '../calendar/CalendarWrapper';
import RotatingLoader from '../common/ui/RotatingLoader';

export const waitingListFetcher =
  (date?: string) =>
  async ({ search, perPage, page }: { search?: string; page?: number; perPage: number }) =>
    WaitingListService.getWaitingList(undefined, page, perPage, search, date);

export const WaitingListTable = React.memo(() => {
  const { t } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const date = searchParams.get('date');
  const [searchDate, setSearchDate] = useState<string | undefined>(date || undefined);
  const { authState } = useAuth();
  const account = authState.account as unknown as AccountData;
  const timeFormat = getTimeFormat(account);
  const language = useLanguage();
  const { shortDateFormat } = getDateFormat(account);
  const hasWaitingListEnabled = account.experiences[0].order_pages.some(
    (orderPage) => orderPage.waiting_list?.enabled === true,
  );

  const tableDetails: CellProps<WaitingListItemData>[] = [
    {
      id: 'orderDate',
      alignment: 'center',
      label: t('waitinglist.askedDate'),
      render: (waitingListItem: WaitingListItemData) => {
        const momentDate = moment(waitingListItem.date);
        const calendarView = localStorage.getItem('webook-calendarview');
        // @ts-ignore
        const weekStart = momentDate.clone().startOf(calendarView === CalendarView.THREE_DAY ? 'day' : calendarView);
        return (
          <Link to={`/dashboard/calendar/${weekStart.format(DATE_FORMAT)}`}>{momentDate.format(shortDateFormat)}</Link>
        );
      },
    },

    {
      id: 'name',
      alignment: 'center',
      label: t('waitinglist.name'),
      render: (waitingListItem: WaitingListItemData) => `${waitingListItem.firstname} ${waitingListItem.lastname}`,
    },
    {
      id: 'num_persons',
      alignment: 'center',
      label: t('waitinglist.num_persons'),
      render: (waitingListItem: WaitingListItemData) => waitingListItem.persons,
    },
    {
      id: 'phone',
      alignment: 'center',
      label: t('waitinglist.phone'),
      render: (waitingListItem: WaitingListItemData) => waitingListItem.phone,
    },
    {
      id: 'email',
      alignment: 'center',
      label: t('waitinglist.email'),
      render: (waitingListItem: WaitingListItemData) => (
        <Typography style={{ textTransform: 'none' }}>{waitingListItem.email}</Typography>
      ),
    },
    {
      id: 'workshop',
      alignment: 'center',
      label: t('orders.workshop'),
      render: (waitingListItem: WaitingListItemData) => (
        <WorkshopCircle account={account} orderPageId={waitingListItem.order_page_id} language={language} withTooltip />
      ),
    },
    {
      id: 'createdat',
      alignment: 'center',
      label: t('waitinglist.createdat'),
      render: (waitingListItem: WaitingListItemData) => (
        <Typography>
          {TimeUtils.getLocalTime(waitingListItem.created_at, account.timezone).format(
            `${shortDateFormat} ${timeFormat}`,
          )}
        </Typography>
      ),
    },
  ];

  const handleRemoveDate = () => {
    searchParams.delete('date');
    setSearchParams(searchParams);
    setSearchDate(undefined);
  };

  const { TableComponent, isLoadingData } = useTable<WaitingListItemData>({
    defaultRowsPerPage: 10,
    opId: `getWaitingList${searchDate}`, // so searchrefreshes
    fetcher: waitingListFetcher(searchDate),
    tableDetails,
    actionsComponent: searchDate ? (
      <Grid container>
        <Chip
          color="warning"
          label={t('waitinglist.filteredForDate', { date: moment(searchDate).format(shortDateFormat) })}
          onDelete={handleRemoveDate}
          deleteIcon={<CancelIcon onMouseDown={(event) => event.stopPropagation()} />}
        />
      </Grid>
    ) : null,
  });

  if (!hasWaitingListEnabled) {
    return (
      <Grid container>
        <Typography variant={'h6'}>{t('waitinglist.notEnabled')}</Typography>
      </Grid>
    );
  }
  if (isLoadingData) {
    return (
      <Grid
        container
        sx={{
          minHeight: '200px',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <RotatingLoader />
      </Grid>
    );
  }
  return <Grid container>{TableComponent}</Grid>;
});
