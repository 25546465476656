import React from 'react';
import { Grid } from '@mui/material';
import UnavailabilityBox from './calendar-items/UnavailabilityBox';
import { NoteData, UnavailabilityData } from '../api';
import NoteBox from './calendar-items/NoteBox';

type AllDayCalendarCellProps = {
  unavailabilities: UnavailabilityData[];
  notes: NoteData[];
  showUnavailability: boolean;
};
export const AllDayCalendarCell = ({ unavailabilities, notes, showUnavailability }: AllDayCalendarCellProps) => {
  const allDayUnavliabilities = unavailabilities?.filter((item) => item.all_day) || [];
  const allDayNotes = notes?.filter((item) => item.all_day) || [];
  return (
    <Grid flexDirection="column">
      {showUnavailability
        ? allDayUnavliabilities.map((unavailability) => {
            return <UnavailabilityBox key={unavailability.id} unavailability={unavailability} />;
          })
        : null}
      {allDayNotes.map((note) => {
        return <NoteBox key={note.id} note={note} />;
      })}
    </Grid>
  );
};
