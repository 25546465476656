import * as yup from 'yup';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import React from 'react';
import { Form, Formik } from 'formik';
import { LoadingButton } from '@mui/lab';
import { omit } from 'lodash';
import { usePostHog } from 'posthog-js/react';
import { KeyedMutator } from 'swr/_internal';
import Typography from '@mui/material/Typography';
import { AccountData, AccountService, OrderPageData } from '../../../api';
import { OrderPageUpdate } from '../../OrderPageSettingsPage';
import { UseAuthPermissions } from '../../../session/UseAuthPermissions';
import { MaxStationsRestrictionTable } from '../../MaxStationsRestrictionTable';
import { OrderPagesMultiSelect } from '../../../userSettings/OrderPagesMultiSelect';

type RestrictionsSettingsSectionProps = {
  orderPage: OrderPageData;
  orderPageInitialValues: OrderPageUpdate;
  updateLocalAccount: KeyedMutator<AccountData>;
  setIsUpdatedSnackOpen: (value: boolean) => void;
  account: AccountData;
};

export type RestrictionsFormValues = {
  availability_settings: {
    parallel_order_page_ids: string[];
  };
};

export const RestrictionsSection = ({
  orderPage,
  orderPageInitialValues,
  updateLocalAccount,
  setIsUpdatedSnackOpen,
  account,
}: RestrictionsSettingsSectionProps) => {
  const { t } = useTranslation();
  const { readOnly } = UseAuthPermissions();
  const posthog = usePostHog();
  const validationSchema = yup.object().shape({});
  const initialValues: RestrictionsFormValues = {
    availability_settings: { parallel_order_page_ids: orderPage.availability_settings.parallel_order_page_ids || [] },
  };

  const handleSubmit = async (values: RestrictionsFormValues) => {
    try {
      posthog.capture('Editing settings', { section: 'RestrictionsSection', settings: values });
      const updatedAccount = await AccountService.updateOrderPage(
        omit(
          {
            ...orderPageInitialValues,
            duration: (orderPageInitialValues.duration || 0) * 60,
            ...values,
            id: orderPage.id,
          },
          'availability_settings.restrictions',
          'objects',
        ),
      );
      await updateLocalAccount(updatedAccount);
      setIsUpdatedSnackOpen(true);
    } catch (e) {
      console.error(e);
    }
  };
  return (
    <Formik
      enableReinitialize
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}>
      {({ values, isSubmitting, dirty, handleSubmit, errors, touched, handleChange }) => {
        const formIsDisabled = readOnly || isSubmitting;
        return (
          <Form onSubmit={handleSubmit}>
            <Grid container justifyContent="space-between" flexWrap="nowrap" alignItems="flex-start">
              <Grid container flexDirection="column" gap={2}>
                <Grid item>
                  <MaxStationsRestrictionTable orderPage={orderPage} />
                </Grid>
                <Grid item>
                  <Typography fontWeight={700}>{t('parallelOrderPages.title')}</Typography>
                  <OrderPagesMultiSelect
                    exceptOrderPageId={orderPage.id}
                    values={values}
                    errors={errors}
                    touched={touched}
                    handleChange={handleChange}
                    orderPagesFieldName="availability_settings.parallel_order_page_ids"
                    account={account}
                  />
                </Grid>
              </Grid>
              <LoadingButton
                size="large"
                loading={isSubmitting}
                type="submit"
                variant="contained"
                color="primary"
                disabled={!dirty || formIsDisabled}>
                {t('editOrderPage.orderPageBufferSubmit')}
              </LoadingButton>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};
